import { Link, useNavigate } from "react-router-dom";
import { FormRow, LoginAndSignupLayout } from "../components";
import { useFormInput } from "../hooks";
import { toastHandler } from "../utils/utils";
import { ToastType } from "../constants/constants";
import { PostWithoutToken } from "../utils/ApiHandlers";
import "./style/LoginandSignup.css";
import { useUser } from "../contexts/UserContext";
import CustomSelect from "../components/CustomSelect";
import { COUNTRYCODES } from "./LoginPage";
import { useEffect } from "react";

const SignupPage = () => {
	const { userData } = useUser();

	const navigate = useNavigate();

	const { userInputs, handleInputChange } = useFormInput({
		phone: "",
		name: "",
	});

	const handleSignUp = (e) => {
		e.preventDefault();
		const { phone, name } = userInputs;
		const formData = new FormData();
		formData.append("phone", phone.trim());
		formData.append("name", name.trim());
		PostWithoutToken("user/signup", formData)
			.then((res) => {
				if (res.data.result == 1) {
					toastHandler(ToastType.Success, res.data.message);
					localStorage.setItem("userId", res.data.tempUserId);
					navigate("/otpverify");
				} else {
					toastHandler(ToastType.Info, res.data.message);
				}
			})
			.catch((err) => {
				console.error(err);
				toastHandler(ToastType.Error, "Failed to send OTP");
			});
	};

	const handleSelect = (value) => {
		console.log(value);
	};

	useEffect(() => {
		const sideNav = document.getElementsByTagName("body")[0];
		sideNav.classList.remove("navopen");
	}, []);

	//  if user is registered and trying to Signup '/signup' through url, show this and navigate to home using useNavigateIfRegistered().
	if (!!userData) {
		return <main className="full-page"></main>;
	}

	return (
		<LoginAndSignupLayout>
			<div className="login__screen">
				<div className="motion_bg">
					<img src="../img/splash_screenbg.png" alt="" />
				</div>
				<div className="login_body">
					<div className="topad_banner">
						<img src="../img/matcha1.png" alt="" /> Sign Up and Get a Bubble Tea
						free with your first order !
					</div>
					<div className="logo_top">
						<img src={"../img/logo.png"} alt="" />
					</div>
					<div className="subtext_top">
						<img src={"../img/subtitle-logo.png"} alt="" />
					</div>
					<div className="signup_body">
						<div className="heading_top">Sign up with your phone number.</div>
						<div className="subheading_top">
							We’ll send you an OTP for confirmation and to keep your account
							safe
						</div>
						<form className="signup" onSubmit={handleSignUp}>
							<div
								className="form_field"
								style={{ display: "flex", gap: "8px" }}
							>
								<CustomSelect
									options={COUNTRYCODES}
									onSelect={handleSelect}
									placeHolder=""
									valueField="value"
									nameField="key"
									defaultValue={{ key: "+91", value: "+91" }}
								/>
								<FormRow
									text="Mobile"
									type="number"
									name="phone"
									id="phone"
									placeholder="Your mobile number"
									value={userInputs.phone}
									handleChange={handleInputChange}
								/>
							</div>
							<div className="form_field">
								<FormRow
									text="Your Name"
									type="text"
									name="name"
									id="name"
									placeholder="Your Name"
									value={userInputs.name}
									handleChange={handleInputChange}
								/>
							</div>
							<div className="form_field">
								<button className="submit_but" type="submit">
									Sign Up
								</button>
								<div className="btngroup">
									<button className="secondry_but">
										<Link to="/login">Log In </Link>
									</button>
									<button className="secondry_but">
										<Link to="/">Cancel</Link>
									</button>
								</div>
							</div>
						</form>
					</div>
					<div className="term__section">
						<label htmlFor="term">
							<input id="term" type="checkbox" name="term" defaultChecked />{" "}
							<span>
								By signing up. you agree to the{" "}
								<a href="#"> Terms of service</a> and{" "}
								<a href="#">Privacy policy .</a>
							</span>
						</label>
					</div>
				</div>
			</div>
		</LoginAndSignupLayout>
	);
};

export default SignupPage;
