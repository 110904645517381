import axios from "axios";

export const BASE_API = "https://staging.app.geezygo.co/api_v1/";

export const PostWithoutToken = async (url, data) => {
    
    let apiUrl = BASE_API + url
	return await axios
		.post(apiUrl, data)
		.then(function (response) {
			if (response?.headers?.access_token) {
				localStorage.setItem("access_token", response?.headers?.access_token);
			}

			return response.data;
		})
		.catch(function (error) {
			if (error?.response?.data) {
				return error.response.data;
			} else {
				return error;
			}
		});
};

export const GetWithoutToken = async (url) => {
	return await axios
		.get(BASE_API + url, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			if (error?.response?.data) {
				return error.response.data;
			} else {
				return error;
			}
		});
};

export const verifyTokenExpired = async (url, data) => {
	return await axios
		.post(BASE_API + url, data, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			if (error?.response?.data) {
				return error.response.data;
			} else {
				return error;
			}
		});
};

export const PostData = async (url, data, contentType = "application/json") => {
	return await axios
		.post(BASE_API + url, data, {
			headers: {
				"Content-Type": contentType,
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		})
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			if (error?.response?.status === 401) {
				setTimeout(() => {
					localStorage.clear();
					window.location.href = "/";
				}, 1000);
			}
			return error.response.data;
		});
};

export const GetData = async (url) => {
	return await axios
		.get(BASE_API + url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		})
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			if (error?.response) {
				if (error?.response?.status === 401) {
					setTimeout(() => {
						localStorage.clear();
						window.location.href = "/";
					}, 1000);
				}
				return error?.response?.data;
			} else {
				return error;
			}
		});
};
