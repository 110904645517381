import { useEffect, useState } from "react";
import { GetWithoutToken } from "../../utils/ApiHandlers";
import { toastHandler } from "../../utils/utils";
import { ToastType } from "../../constants/constants";
import ProductAddRemove from "../common/ProductAddRemove";
import { useLoader } from "../../contexts/LoaderContext";
import ReadMoreReadLess from "../ReadMoreReadLess";

const FeaturedProducts = () => {
	const [bestSellerProductsData, setBestSellerProductsData] = useState(null);

	const { showLoader, hideLoader } = useLoader();

	useEffect(() => {
		bestSellerProducts();
	}, []);

	const bestSellerProducts = () => {
		showLoader();
		GetWithoutToken("bestsellerprocut")
			.then((res) => {
				setBestSellerProductsData(res.data);
				hideLoader();
			})
			.catch((err) => {
				hideLoader();
				toastHandler(ToastType.Error, "Something went wrong");
			});
	};

	if (bestSellerProductsData?.data?.top_grossing?.length > 0) {
		return (
			<>
				<div className="component_sellerslider component_style slider_component">
					<div className="component_title">Best-Sellers</div>
					<div className="slider_wrapper">
						<div className="slider_container">
							{bestSellerProductsData &&
								bestSellerProductsData.top_grossing.length > 0 &&
								bestSellerProductsData.top_grossing.map((item, index) => {
									return (
										<div className="slider_item" key={index}>
											<div className="seller_card">
												<div className="items_poster">
													<img src={item.imageUrl} alt="" />
												</div>
												<div className="badge_tag">
													<span className="foodtype veg"></span>
													<span className="badge">Bestseller</span>
												</div>
												<div className="item_details">
													<div className="item_name">{item.name}</div>
													<div className="inr_price">₹ {item.price}</div>
												</div>
												<div className="item_discr">
													<div className="item_srtinfo">
														<ReadMoreReadLess charLimit={100}>
															{item.description}
														</ReadMoreReadLess>
													</div>
													<div className="item_crtaction_btn">
														<ProductAddRemove product={item} />
													</div>
												</div>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</div>

				{/*<section className='section'>
		//   <Title>Featured Products</Title>
	
		//   <div className={`container ${styles.featuredCenter}`}>
		//     {featuredProductsList.map((singleProduct) => (
		//       <ProductCard key={singleProduct._id} product={singleProduct} />
		//     ))}
		//   </div>
		// </section>*/}
			</>
		);
	}
};

export default FeaturedProducts;
