import { Link, useLocation } from "react-router-dom";
import { useSearch } from "../../contexts/SearchContext";

const Header = () => {
	const location = useLocation();
	const { searchValue, updateSearchValue, triggerSearch, searchSuggestions, setSearchResults } = useSearch();

	const removeHeader =
		location.pathname === "/order-history" ||
		location.pathname.includes("/order") ||
		location.pathname === "/checkout" ||
		location.pathname.includes("/profile") ||
		location.pathname === "/about-us" ||
		location.pathname === "/manage-address" ||
		location.pathname === "/support";

	if (removeHeader) {
		return null;
	}

	const handleSuggestionClick = () => {
		setSearchResults({items: searchSuggestions, result: searchSuggestions.length})
	}

	return (
		<>
			<div className="header">
				<div className="header_hero">
					<Link to={"/"} className="sm_logo">
						<img src="/img/sm-logo.svg" alt="Boba Bhai" />
					</Link>
					{location.pathname !== '/' && <div className="glob_search">
						<div className="search_cont">
							<input
								className="search_app"
								type="text"
								placeholder="What would like to have?"
								value={searchValue}
								onChange={(e) => updateSearchValue(e.target.value)}
							/>
							<span className="seachicon" onClick={() => triggerSearch()}>
								<svg
									width="15"
									height="15"
									viewBox="0 0 15 15"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.8633 13.9373L9.68122 10.7543C9.38855 10.4615 9.38859 9.98697 9.68129 9.69426C9.97406 9.4015 10.4487 9.40153 10.7415 9.69433L13.9236 12.8773C14.2163 13.1701 14.2162 13.6446 13.9235 13.9373C13.6307 14.2301 13.1561 14.2301 12.8633 13.9373Z"
										fill="#C2CCDE"
										fill-opacity="0.25"
									/>
									<path
										d="M0.899164 4.26727C1.3396 2.62355 2.62349 1.33966 4.26721 0.899225C5.91093 0.458791 7.66476 0.928728 8.86805 2.13202C10.0713 3.3353 10.5413 5.08913 10.1008 6.73286C9.66041 8.37658 8.37652 9.66047 6.7328 10.1009C5.08907 10.5413 3.33524 10.0714 2.13196 8.86811C0.928667 7.66482 0.45873 5.91099 0.899164 4.26727Z"
										fill="#C2CCDE"
										fill-opacity="0.25"
									/>
									<path
										d="M8.86126 8.87465L9.68108 9.69447M9.68122 10.7543L12.8633 13.9373C13.1561 14.2301 13.6307 14.2301 13.9235 13.9373C14.2162 13.6446 14.2163 13.1701 13.9236 12.8773L10.7415 9.69433C10.4487 9.40153 9.97406 9.4015 9.68129 9.69426C9.38859 9.98697 9.38855 10.4615 9.68122 10.7543ZM2.13196 8.86811C0.928667 7.66482 0.45873 5.91099 0.899164 4.26727C1.3396 2.62355 2.62349 1.33966 4.26721 0.899225C5.91093 0.458791 7.66476 0.928728 8.86805 2.13202C10.0713 3.3353 10.5413 5.08913 10.1008 6.73286C9.66041 8.37658 8.37652 9.66047 6.7328 10.1009C5.08907 10.5413 3.33524 10.0714 2.13196 8.86811Z"
										stroke="#414141"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</span>
						</div>
						{searchSuggestions.length > 0 && <div className="suggestion_container">
							{searchSuggestions.map((product, index) => {
								return <span onClick={handleSuggestionClick} key={index}>{product.name}</span>
							})}
							</div>}
					</div>}
				</div>
				<div className="header_sub">
					<div className="subhead_cont">
						<div className="twcolnm">
							<div className="usr_loc">
								<svg
									width="10"
									height="10"
									viewBox="0 0 10 10"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect
										width="10"
										height="10"
										fill="white"
										fill-opacity="0.01"
									/>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M5 8.75C4.05 8.75 2.5 5.13083 2.5 3.75C2.5 3.08696 2.76339 2.45107 3.23223 1.98223C3.70107 1.51339 4.33696 1.25 5 1.25C5.66304 1.25 6.29893 1.51339 6.76777 1.98223C7.23661 2.45107 7.5 3.08696 7.5 3.75C7.5 5.13083 5.95 8.75 5 8.75ZM5 5C5.15934 5 5.31711 4.96862 5.46432 4.90764C5.61153 4.84666 5.74529 4.75729 5.85796 4.64462C5.97062 4.53195 6.06 4.3982 6.12097 4.25099C6.18195 4.10378 6.21333 3.946 6.21333 3.78667C6.21333 3.62733 6.18195 3.46955 6.12097 3.32234C6.06 3.17514 5.97062 3.04138 5.85796 2.92871C5.74529 2.81604 5.61153 2.72667 5.46432 2.66569C5.31711 2.60472 5.15934 2.57333 5 2.57333C4.6782 2.57333 4.36959 2.70117 4.14204 2.92871C3.9145 3.15625 3.78667 3.46487 3.78667 3.78667C3.78667 4.10846 3.9145 4.41708 4.14204 4.64462C4.36959 4.87217 4.6782 5 5 5Z"
										fill="#FB8A00"
									/>
								</svg>{" "}
								Location- Indiranagar
							</div>
							<div className="stre_status openstus">Open</div>
						</div>
						<div className="main_title">
							Boba Bhai <br /> Old Airport Road
						</div>
						<div className="intal_advet">
							<div className="advet_item">
								<img src="../img/Taro-04.png" alt="" width="25" />
								Refer to a friend & Get a coupon 30% Off
							</div>
							<div className="advet_item">
								<img src="../img/matcha-03.png" alt="" width="25" />
								Get a Bubble Tea free on your first Order
							</div>
							<div className="advet_item">
								<img src="../img/milk-tea-04.png" alt="" width="25" />
								Free Delivery on orders above ₹299
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="transt_status">
				<div className="delvry_action">
					<input name="transt_status" type="radio" id="delvry_action" checked />
					<label htmlFor="delvry_action">Delivery</label>
				</div>
				<div className="delvry_action">
					<input name="transt_status" type="radio" id="pickup_action" />
					<label htmlFor="pickup_action">Pick Up</label>
				</div>
			</div>
		</>

		//  {/* <nav className={styles.nav}>
		//     <div
		//       className={`container ${styles.navCenter} ${
		//         isNavInLoginOrSignupPage && styles.loginNavCenter
		//       }`}
		//     >
		//       <Link to='/'>
		//         <h3 className={styles.logo}>
		//           Gada{' '}
		//           <span className={styles.logoTheme}>
		//             Elec{!isNavInLoginOrSignupPage ? '.' : 'tronics'}
		//           </span>
		//         </h3>
		//       </Link>

		//       {!isNavInLoginOrSignupPage && (
		//         <>
		//           <SearchBar />
		//           <LinksContainer />
		//         </>
		//       )}
		//     </div>
		//   </nav>*/}
	);
};

export default Header;
