import React from 'react';

const FormRow = ({
  text,
  type,
  handleChange,
  value,
  placeholder,
  name,
  disabled = false,
  className = "",
}) => {
  return (
     <>
      {/* <label className='form-label' htmlFor={name}>
        {text}
      </label> */}
      <input       
        type={type}
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        required={true}
        disabled={disabled}
        className={className}
      />
    </>
  );
};

export default FormRow;
