import React from "react";

export default function ProductCardSkeleton() {
	return (
		<>
			<style>{` .skeleton {
            padding:0 15px;
            max-width: 100%;
            width: 100%;
            margin-bottom: 20px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center
       }
        .skeleton .square {
            height: 80px;
            border-radius: 5px;
            background: rgba(130, 130, 130, 0.2);
            background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
            background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
            background-size: 800px 100px;
            animation: wave-squares 2s infinite ease-out;
       }
        .skeleton .line {
            height: 12px;
            margin-bottom:6px;
            border-radius: 2px;
            background: rgba(130, 130, 130, 0.2);
            background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
            background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
            background-size: 800px 100px;
            animation: wave-lines 2s infinite ease-out;
       }
        .skeleton-right{
           flex:1;
       }
        .skeleton-left{
           flex:2;
           padding-right:15px;
       }
        .flex1{
           flex: 1;
       }
        .flex2{
           flex: 2;
       }
        .skeleton .line:last-child{
           margin-bottom: 0;
       }
        .h8{
           height: 8px !important;
       }
        .h17{
           height: 17px !important;
       }
        .w40{
           width:40% !important;
       }
        .w50{
           width: 50% !important
       }
        .w75{
           width: 100% !important
       }
       
        @keyframes wave-lines {
           0% {
               background-position: -468px 0;
           }
            100% {
               background-position: 468px 0;
           }
       }
        @keyframes wave-squares {
           0% {
               background-position: -468px 0;
           }
            100% {
               background-position: 468px 0;
           }
       }
       `}</style>
			<div className="skeleton">
				<div className="skeleton-left flex1">
					<div className="square"></div>
				</div>
				<div className="skeleton-right flex2">
					<div className="line h17 w40 m10"></div>
					<div className="line"></div>
					<div className="line h8 w50"></div>
					<div className="line  w75"></div>
				</div>
			</div>
		</>
	);
}
