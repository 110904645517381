import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import StickyCart from "../common/StickyCart";
import Navbar from "../Navbar/Navbar";
import Loader from "../Loader";
import "./LayoutStyle.css";
import { useLoader } from "../../contexts/LoaderContext";
import { useLocation } from "react-router-dom";
import SplashScreen from "../SplashScreen/SplashScreen";
import SideNavigation from "../../components/common/SideNavigation";

export default function CommonLayout({ children }) {
	const { loading } = useLoader();
	const location = useLocation();
	const showNaveStickyHeader =
		location.pathname === "/login" ||
		location.pathname === "/signup" ||
		location.pathname === "/otpverify"


	const [splashLoading, setSplashLoading] = useState(true);
	const [isSplashLoaded, setIsSplashLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setSplashLoading(false);
			setIsSplashLoaded(true);
		}, 4000);
		return () => clearTimeout(timer);
	}, []);

	if (showNaveStickyHeader) {
		return (
			<>
				<Loader isLoading={loading} />
			</>
		);
	}

	return (
		<>
			<Loader isLoading={loading} />
			{splashLoading && !isSplashLoaded && location.pathname === "/" ? (
				<SplashScreen />
			) : (
				<>
					<div className="bpabh_maincontainer">
						<Header />
						{children}
					</div>
					<StickyCart />
					<Navbar />
					<SideNavigation />
				</>
			)}
		</>
	);
}
