import React from "react";
import { useCart } from "../../contexts/CartContext";
import { Link, useLocation } from "react-router-dom";

export default function StickyCart() {
	const location = useLocation();
	const { totalItems, totalPrice } = useCart();
	if (totalItems === 0) {
		return null;
	}

	if (
		location.pathname === "/checkout" ||
		location.pathname === "/cart" ||
		location.pathname === "/about-us" ||
		location.pathname.includes("profile") ||
		location.pathname === "/support" ||
		location.pathname.includes("/order")
	) {
		return <></>;
	}

	return (
		<Link to="/cart" class="sticky_cart">
			<div class="cart_items">
				<span class="total_pric">₹ {totalPrice}</span>
				<span style={{ marginLeft: "10px" }} class="items_othdetil">
					{totalItems} Item Added
				</span>
			</div>
			<div class="cart_icon">
				<div class="cart_wrap">
					<div class="crtsvg">
						<svg
							width="40"
							height="40"
							viewBox="0 0 40 40"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.7187 8.70836L9.39349 7.74731H9.43341C9.63922 8.40987 9.70308 8.65664 9.70447 8.66011C9.70794 8.67712 9.7128 8.69274 9.71905 8.70836H9.7187Z"
								fill="#F7F7F7"
							></path>
							<path
								d="M34.6249 9.7851L33.5927 15.4108C33.153 17.8014 31.284 19.3119 28.5897 19.4538L14.7526 20.604C14.1553 22.0058 14.3396 22.9825 15.3104 23.5812C15.7175 23.8328 16.21 23.9654 16.7341 23.9654H29.987C30.3858 23.9654 30.7096 24.2892 30.7096 24.688C30.7096 25.0868 30.3858 25.4106 29.987 25.4106H16.7341C15.9427 25.4106 15.1875 25.2034 14.5517 24.8109C13.8169 24.3579 13.3137 23.7211 13.0957 22.9669C12.8378 22.0756 12.9718 21.0361 13.4969 19.8741L9.72495 8.72757C9.72253 8.72167 9.72148 8.71438 9.71905 8.70813C9.71315 8.69252 9.70829 8.6769 9.70448 8.65989C9.70344 8.65642 9.63957 8.40931 9.43341 7.74709C9.3286 7.41217 9.18769 6.97139 8.99992 6.40288C8.54213 5.01182 7.15732 5.01182 7.00183 5.01772H4.56782C4.16903 5.01772 3.84521 4.69495 3.84521 4.29512C3.84521 3.89529 4.16903 3.57252 4.56782 3.57252H6.95984C7.86777 3.53885 9.72738 3.98935 10.3729 5.951C10.6582 6.82041 10.8401 7.40002 10.9498 7.76479L33.1776 8.02614C33.6174 8.031 34.0304 8.22952 34.3098 8.5693C34.5892 8.90909 34.7037 9.3523 34.6253 9.7844L34.6249 9.7851Z"
								fill="#F7F7F7"
							></path>
							<path
								d="M17.0943 30.2987C17.0943 32.0257 15.6887 33.4314 13.9617 33.4314C12.2346 33.4314 10.8279 32.0257 10.8279 30.2987C10.8279 28.5717 12.2336 27.166 13.9617 27.166C15.6897 27.166 17.0943 28.5717 17.0943 30.2987Z"
								fill="#F7F7F7"
							></path>
							<path
								d="M27.5769 33.4314C29.3071 33.4314 30.7096 32.0288 30.7096 30.2987C30.7096 28.5686 29.3071 27.166 27.5769 27.166C25.8468 27.166 24.4443 28.5686 24.4443 30.2987C24.4443 32.0288 25.8468 33.4314 27.5769 33.4314Z"
								fill="#F7F7F7"
							></path>
						</svg>
					</div>
					<div class="numbitems">{totalItems}</div>
				</div>
			</div>
		</Link>
	);
}
