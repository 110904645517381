import React, { createContext, useContext, useState, useEffect } from "react";
import { GetWithoutToken } from "../utils/ApiHandlers";
import { toastHandler } from "../utils/utils";
import { ToastType } from "../constants/constants";

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
	const [searchValue, setSearchValue] = useState("");
	const [searchResults, setSearchResults] = useState(null);
	const [storeId, setStoreId] = useState(null);
	const [searchSuggestions, setSearchSuggestions] = useState([]);

	const updateSearchValue = (value) => {
		if (value === "") {
			setSearchSuggestions([]);
		}
		setSearchValue(value);
	};

	useEffect(() => {
		if (searchResults) {
			setSearchSuggestions([]);
		}
	}, [searchResults]);

	useEffect(() => {
		const debounceTimeout = setTimeout(() => {
			if (searchValue) {
				triggerSearch(true);
			}
		}, 500);

		return () => clearTimeout(debounceTimeout);
	}, [searchValue]);

	const triggerSearch = (suggestion) => {
		if (searchValue) {
			GetWithoutToken(`category/search/${searchValue}/${storeId}`)
				.then((res) => {
					if (suggestion) {
						setSearchSuggestions(res.data.items);
					} else {
						setSearchResults(res.data);
					}
				})
				.catch((err) => {
					toastHandler(ToastType.Error, "Something went wrong");
				});
		}
	};

	return (
		<SearchContext.Provider
			value={{
				searchValue,
				updateSearchValue,
				triggerSearch,
				searchResults,
				setSearchResults,
				setStoreId,
				searchSuggestions,
			}}
		>
			{children}
		</SearchContext.Provider>
	);
};
