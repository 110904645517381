import React, { createContext, useContext, useReducer } from 'react';

// Initial state
const initialState = {
  addressList: [],  // List of addresses
  selectedAddress: null  // The currently selected address
};

// Reducer to manage state
const addressReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ADDRESS_LIST':
      return {
        ...state,
        addressList: action.payload,
      };
    case 'SELECT_ADDRESS':
      return {
        ...state,
        selectedAddress: action.payload,
      };
    case 'ADD_ADDRESS':
      return {
        ...state,
        addressList: [...state.addressList, action.payload],
      };
    case 'REMOVE_ADDRESS':
      return {
        ...state,
        addressList: state.addressList.filter(address => address.id !== action.payload),
      };
    default:
      return state;
  }
};

// Create context
const AddressContext = createContext();

// Provider component
export const AddressProvider = ({ children }) => {
  const [state, dispatch] = useReducer(addressReducer, initialState);

  const setAddressList = (addresses) => {
    dispatch({ type: 'SET_ADDRESS_LIST', payload: addresses });
  };

  const selectAddress = (address) => {
    dispatch({ type: 'SELECT_ADDRESS', payload: address });
  };

  const addAddress = (address) => {
    dispatch({ type: 'ADD_ADDRESS', payload: address });
  };

  const removeAddress = (addressId) => {
    dispatch({ type: 'REMOVE_ADDRESS', payload: addressId });
  };

  return (
    <AddressContext.Provider
      value={{
        addressList: state.addressList,
        selectedAddress: state.selectedAddress,
        setAddressList,
        selectAddress,
        addAddress,
        removeAddress,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
};

export const useAddress = () => {
  return useContext(AddressContext);
};