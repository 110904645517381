import { Navigate, useLocation } from 'react-router-dom';
import { LOGIN_TOAST } from '../utils/utils';
import { useUser } from '../contexts/UserContext';

const PrivateRoute = ({ children }) => {
  const { userData } = useUser();
  const location = useLocation();

  let routeToRedirect = location?.pathname;

  if (!userData) {
    routeToRedirect !== '/profile' ? LOGIN_TOAST() : (routeToRedirect = '/');
  }

  if (!userData) {
    return (
      <>
        <Navigate
          to='/login'
          state={{ from: routeToRedirect }}
          replace
        ></Navigate>
        <main className='full-page'></main>
      </>
    );
  }

  return children;
};

export default PrivateRoute;
