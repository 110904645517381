import {
	Categories,
	FeaturedProducts,
	TodaySpotlight,
} from "../components";
import React, { useState, useEffect } from "react";
import { GetWithoutToken } from "../utils/ApiHandlers";
import { toastHandler } from "../utils/utils";
import { ToastType } from "../constants/constants";

const Home = () => {
	const [categoryData, setCategoryData] = useState(null);

	useEffect(() => {
		getCategoryData();
	}, []);

	const getCategoryData = () => {
		GetWithoutToken("category")
			.then((res) => {
				setCategoryData(res.data);
			})
			.catch((err) => {
				toastHandler(ToastType.Error, "Something went wrong");
			});
	};

	return (
		<div className="bpabh_maincontainer">
			<TodaySpotlight data={categoryData} />
			<FeaturedProducts />
			<Categories categoryData={categoryData} />
		</div>
	);
};

export default Home;
