import Navbar from './Navbar/Navbar';

const LoginAndSignupLayout = ({ children }) => {
  return (
    <> 
        {children}
    </>
  );
};

export default LoginAndSignupLayout;
