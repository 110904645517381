import React from "react";
import { PRODUCTTYPE } from "../ProductListingPage";
import ProductAddRemove from "../../../components/common/ProductAddRemove";
import ReadMoreReadLess from "../../../components/ReadMoreReadLess";

export default function ProductCard({ product, category }) {
	return (
		<div className="vcard_item">
			<div className="items_poster">
				<span
					className={`foodtype ${
						product.product_type == PRODUCTTYPE.VEG ? "veg" : "nonveg"
					}`}
				></span>
				<img src={product.imageUrl} alt="" />
			</div>
			<div className="item_detail">
				{category?.title && (
					<div className="badge_tag">
						<span className="badge">{category.title}</span>
					</div>
				)}
				<div className="item_name">{product.name}</div>
				{product?.description && (
					<div className="item_discr">
						<div className="item_srtinfo">
							<ReadMoreReadLess charLimit={100}>
								{product.description}
							</ReadMoreReadLess>
						</div>
					</div>
				)}
				<div className="pric_crt">
					<div className="inr_price">₹ {product.price}</div>
					<ProductAddRemove product={product} />
				</div>
			</div>
		</div>
	);
}
