import { Link, useLocation } from "react-router-dom";
import { useCart } from "../../contexts/CartContext";

const Navbar = () => {
	const location = useLocation();

	const { totalItems } = useCart();

	const hideNavBar =
		location.pathname === "/login" ||
		location.pathname === "/signup" ||
		location.pathname === "/profile/add-address" ||
		location.pathname.includes("/order-process");
	const handleToggleClass = () => {
		const sideNav = document.getElementsByTagName("body")[0];
		sideNav.classList.toggle("navopen");
	};

	if (hideNavBar) {
		return <></>;
	}

	return (
		<div className="sticky_nav">
			<Link
				to="/"
				className={`nav_item ${location.pathname === "/" ? "activenav" : ""}`}
			>
				<i className="nav_icon">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M12 5.40002L18 10.2V18.6H6V10.2L12 5.40002ZM13.8 15V11.4H10.2V15H13.8Z"
							fill="#C2CCDE"
							fill-opacity="0.25"
						/>
						<path
							d="M16.5 9.00002V5.70002H14.7V7.56002L16.5 9.00002Z"
							fill="#C2CCDE"
							fill-opacity="0.25"
						/>
						<path
							d="M12 5.40002L18 10.2V18.6H6V10.2L12 5.40002ZM12 5.40002L3.75 12M12 5.40002L20.25 12M13.8 11.4V15H10.2V11.4H13.8ZM16.5 5.70002V9.00002L14.7 7.56002V5.70002H16.5Z"
							stroke="#414141"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</i>
				<div className="nav_text">Home</div>
			</Link>
			{/* <div className="nav_item ">
				<i className="nav_icon">
					<svg
						width="24"
						height="25"
						viewBox="0 0 24 25"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M5.21184 7.0042C4.42419 7.79185 3.98169 8.86014 3.98169 9.97405C3.98169 11.088 4.42419 12.1562 5.21184 12.9439L11.5758 19.3079C11.8101 19.5422 12.19 19.5422 12.4243 19.3079L18.7883 12.9439C19.5759 12.1562 20.0184 11.088 20.0184 9.97405C20.0184 8.86014 19.5759 7.79185 18.7883 7.0042C18.0006 6.21655 16.9324 5.77405 15.8184 5.77405C14.7045 5.77405 13.6362 6.21655 12.8486 7.0042L12.2074 7.64542C12.0929 7.75991 11.9073 7.75991 11.7928 7.64542L11.1515 7.0042C10.3639 6.21655 9.2956 5.77405 8.18169 5.77405C7.06778 5.77405 5.99949 6.21655 5.21184 7.0042Z"
							fill="#C2CCDE"
							fill-opacity="0.25"
							stroke="#414141"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</i>
				<div className="nav_text">Favourite</div>
			</div> */}
			<Link
				to="/cart"
				className={`nav_item ${
					location.pathname === "/cart" ? "activenav" : ""
				}`}
			>
				<i className="nav_icon">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M5.82273 5.19612C5.8221 5.19403 5.78379 5.04577 5.65947 4.64844H5.63635L5.83148 5.22506C5.82794 5.21569 5.82565 5.20632 5.82273 5.19612Z"
							fill="#5A5A5A"
						/>
						<path
							d="M20.5859 5.1417C20.4174 4.93783 20.1704 4.81872 19.9059 4.8158L6.57069 4.65899C6.50427 4.44013 6.39577 4.09236 6.22376 3.57072C5.83643 2.39352 4.72066 2.12322 4.1759 2.14363H2.74069C2.50142 2.14363 2.30713 2.33792 2.30713 2.57719C2.30713 2.81646 2.50142 3.01075 2.74069 3.01075H4.2011C4.29502 3.00721 5.12528 3.00721 5.39995 3.84185C5.51199 4.18295 5.59653 4.44742 5.65942 4.64837C5.78374 5.04591 5.82206 5.19397 5.82269 5.19605C5.8256 5.20626 5.82768 5.21563 5.83143 5.225C5.83289 5.22854 5.83351 5.23291 5.83497 5.23666L8.09816 11.9246C7.78309 12.622 7.70291 13.2455 7.85743 13.7802C7.9882 14.2325 8.29036 14.6149 8.73101 14.8866C9.11251 15.1222 9.56564 15.2465 10.0404 15.2465H17.9914C18.2313 15.2465 18.4249 15.0522 18.4249 14.8129C18.4249 14.5736 18.2313 14.3794 17.9914 14.3794H10.0404C9.7262 14.3794 9.4305 14.2998 9.18623 14.1488C8.60377 13.7896 8.49319 13.2036 8.85158 12.3625L17.1538 11.6724C18.7704 11.5872 19.8918 10.681 20.1548 9.24659L20.7741 5.87118C20.8218 5.61171 20.7531 5.34578 20.5854 5.14212L20.5859 5.1417ZM19.9218 5.71395L19.3025 9.08936C19.1138 10.1183 18.3118 10.7443 17.1028 10.8063L8.86636 11.4906L6.84953 5.52903L19.8957 5.68292C19.8993 5.68292 19.908 5.68292 19.9159 5.6923C19.9239 5.7025 19.9224 5.71041 19.9218 5.71395Z"
							fill="#5A5A5A"
						/>
						<path
							d="M8.37679 20.0588C7.34037 20.0588 6.49719 19.2156 6.49719 18.1792C6.49719 17.1427 7.34037 16.2996 8.37679 16.2996C9.41322 16.2996 10.2564 17.1427 10.2564 18.1792C10.2564 19.2156 9.41322 20.0588 8.37679 20.0588ZM8.37679 17.1669C7.81849 17.1669 7.36432 17.6211 7.36432 18.1792C7.36432 18.7373 7.81849 19.1916 8.37679 19.1916C8.93509 19.1916 9.38927 18.7375 9.38927 18.1792C9.38927 17.6209 8.93509 17.1669 8.37679 17.1669Z"
							fill="#5A5A5A"
						/>
						<path
							d="M16.546 20.0588C15.5096 20.0588 14.6664 19.2156 14.6664 18.1792C14.6664 17.1427 15.5096 16.2996 16.546 16.2996C17.5824 16.2996 18.4256 17.1427 18.4256 18.1792C18.4256 19.2156 17.5824 20.0588 16.546 20.0588ZM16.546 17.1669C15.9877 17.1669 15.5335 17.6211 15.5335 18.1792C15.5335 18.7373 15.9877 19.1916 16.546 19.1916C17.1043 19.1916 17.5585 18.7375 17.5585 18.1792C17.5585 17.6209 17.1043 17.1669 16.546 17.1669Z"
							fill="#5A5A5A"
						/>
					</svg>{" "}
					<span className="numbitems">{totalItems}</span>
				</i>
				<div className="nav_text">Cart</div>
			</Link>
			{/* <Link to="/" className="nav_item">
				<i className="nav_icon">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M15.0281 5.71397C16.1027 5.21002 17.4228 5.40196 18.3105 6.28964C19.1981 7.17732 19.3901 8.49736 18.8861 9.57201C20.0024 9.97555 20.8 11.0447 20.8 12.3C20.8 13.5554 20.0024 14.6245 18.8861 15.0281C19.3901 16.1027 19.1981 17.4228 18.3105 18.3105C17.4228 19.1981 16.1027 19.3901 15.0281 18.8861C14.6245 20.0024 13.5554 20.8 12.3 20.8C11.0447 20.8 9.97555 20.0024 9.57201 18.8861C8.49736 19.3901 7.17732 19.1981 6.28964 18.3105C5.40196 17.4228 5.21002 16.1027 5.71397 15.0281C4.59774 14.6245 3.80005 13.5554 3.80005 12.3C3.80005 11.0447 4.59774 9.97555 5.71397 9.57201C5.21002 8.49736 5.40196 7.17732 6.28964 6.28964C7.17732 5.40196 8.49736 5.21002 9.572 5.71397C9.97555 4.59774 11.0447 3.80005 12.3 3.80005C13.5554 3.80005 14.6245 4.59774 15.0281 5.71397Z"
							fill="#C2CCDE"
							fill-opacity="0.25"
						/>
						<path
							d="M9.15544 10.95C8.74272 10.5373 8.74272 9.86814 9.15544 9.45543C9.56815 9.04272 10.2373 9.04272 10.65 9.45543C11.0627 9.86814 11.0627 10.5373 10.65 10.95C10.2373 11.3627 9.56815 11.3627 9.15544 10.95Z"
							fill="#C2CCDE"
							fill-opacity="0.25"
						/>
						<path
							d="M13.3402 15.1348C12.9275 14.7221 12.9275 14.0529 13.3402 13.6402C13.7529 13.2275 14.4221 13.2275 14.8348 13.6402C15.2475 14.0529 15.2475 14.7221 14.8348 15.1348C14.4221 15.5475 13.7529 15.5475 13.3402 15.1348Z"
							fill="#C2CCDE"
							fill-opacity="0.25"
						/>
						<path
							d="M15.2084 8.93233L8.63234 15.5084M18.3105 6.28964C17.4228 5.40196 16.1027 5.21002 15.0281 5.71397C14.6245 4.59774 13.5554 3.80005 12.3 3.80005C11.0447 3.80005 9.97555 4.59774 9.572 5.71397C8.49736 5.21002 7.17732 5.40196 6.28964 6.28964C5.40196 7.17732 5.21002 8.49736 5.71397 9.57201C4.59774 9.97555 3.80005 11.0447 3.80005 12.3C3.80005 13.5554 4.59774 14.6245 5.71397 15.0281C5.21002 16.1027 5.40196 17.4228 6.28964 18.3105C7.17732 19.1981 8.49736 19.3901 9.57201 18.8861C9.97555 20.0024 11.0447 20.8 12.3 20.8C13.5554 20.8 14.6245 20.0024 15.0281 18.8861C16.1027 19.3901 17.4228 19.1981 18.3105 18.3105C19.1981 17.4228 19.3901 16.1027 18.8861 15.0281C20.0024 14.6245 20.8 13.5554 20.8 12.3C20.8 11.0447 20.0024 9.97555 18.8861 9.57201C19.3901 8.49736 19.1981 7.17732 18.3105 6.28964ZM10.65 10.95C10.2373 11.3627 9.56815 11.3627 9.15544 10.95C8.74272 10.5373 8.74272 9.86815 9.15544 9.45543C9.56815 9.04272 10.2373 9.04272 10.65 9.45543C11.0627 9.86814 11.0627 10.5373 10.65 10.95ZM14.8348 15.1348C14.4221 15.5475 13.7529 15.5475 13.3402 15.1348C12.9275 14.7221 12.9275 14.0529 13.3402 13.6402C13.7529 13.2275 14.4221 13.2275 14.8348 13.6402C15.2475 14.0529 15.2475 14.7221 14.8348 15.1348Z"
							stroke="#414141"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</i>
				<div className="nav_text">Offers</div>
			</Link> */}
			<Link
				onClick={handleToggleClass}
				className={`nav_item ${
					location.pathname.includes("profile") ? "activenav" : ""
				}`}
			>
				<i className="nav_icon">
					<svg
						width="24"
						height="25"
						viewBox="0 0 24 25"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18 21.7624H6.00005C5.33731 21.7624 4.80005 21.2252 4.80005 20.5624C4.80005 18.5775 6.03149 16.8009 7.89014 16.1043L8.25916 15.966C10.6712 15.062 13.3289 15.062 15.7409 15.966L16.11 16.1043C17.9686 16.8009 19.2 18.5775 19.2 20.5624C19.2 21.2252 18.6628 21.7624 18 21.7624Z"
							fill="#C2CCDE"
							fill-opacity="0.25"
						/>
						<path
							d="M10.1705 12.4226C11.3189 13.0125 12.6812 13.0125 13.8296 12.4226C14.8984 11.8736 15.6641 10.8737 15.9155 9.69878L15.9826 9.3852C16.2397 8.18371 15.9651 6.92973 15.2294 5.94565L15.1216 5.80155C14.3858 4.81734 13.2289 4.23779 12 4.23779C10.7712 4.23779 9.61428 4.81734 8.87846 5.80155L8.77072 5.94565C8.035 6.92973 7.76038 8.18371 8.01748 9.3852L8.08457 9.69878C8.33598 10.8737 9.10172 11.8736 10.1705 12.4226Z"
							fill="#C2CCDE"
							fill-opacity="0.25"
						/>
						<path
							d="M18 21.7624H6.00005C5.33731 21.7624 4.80005 21.2252 4.80005 20.5624C4.80005 18.5775 6.03149 16.8009 7.89014 16.1043L8.25916 15.966C10.6712 15.062 13.3289 15.062 15.7409 15.966L16.11 16.1043C17.9686 16.8009 19.2 18.5775 19.2 20.5624C19.2 21.2252 18.6628 21.7624 18 21.7624Z"
							stroke="#414141"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M10.1705 12.4226C11.3189 13.0125 12.6812 13.0125 13.8296 12.4226C14.8984 11.8736 15.6641 10.8737 15.9155 9.69878L15.9826 9.3852C16.2397 8.18371 15.9651 6.92973 15.2294 5.94565L15.1216 5.80155C14.3858 4.81734 13.2289 4.23779 12 4.23779C10.7712 4.23779 9.61428 4.81734 8.87846 5.80155L8.77072 5.94565C8.035 6.92973 7.76038 8.18371 8.01748 9.3852L8.08457 9.69878C8.33598 10.8737 9.10172 11.8736 10.1705 12.4226Z"
							stroke="#414141"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</i>
				<div className="nav_text">Profile</div>
			</Link>
		</div>

		//  {/* <nav className={styles.nav}>
		//     <div
		//       className={`container ${styles.navCenter} ${
		//         isNavInLoginOrSignupPage && styles.loginNavCenter
		//       }`}
		//     >
		//       <Link to='/'>
		//         <h3 className={styles.logo}>
		//           Gada{' '}
		//           <span className={styles.logoTheme}>
		//             Elec{!isNavInLoginOrSignupPage ? '.' : 'tronics'}
		//           </span>
		//         </h3>
		//       </Link>

		//       {!isNavInLoginOrSignupPage && (
		//         <>
		//           <SearchBar />
		//           <LinksContainer />
		//         </>
		//       )}
		//     </div>
		//   </nav>*/}
	);
};

export default Navbar;
