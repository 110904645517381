import React, { useEffect, useState } from "react";
import "./CartPage.css";
import { useCart } from "../../contexts/CartContext";
import { Link, useNavigate } from "react-router-dom";
import ProductAddRemove from "../../components/common/ProductAddRemove";
import { GetWithoutToken, PostWithoutToken } from "../../utils/ApiHandlers";
import { toastHandler } from "../../utils/utils";
import { ToastType } from "../../constants/constants";
import { useAddress } from "../../contexts/AddressContext";
import { useUser } from "../../contexts/UserContext";

export default function CartPage() {
	const navigate = useNavigate();
	const [couponCodeInput, setCouponCodeInput] = useState(false);
	const {
		products,
		totalPrice,
		applyDiscount,
		discountPrice,
		discountCode,
		removeDiscount,
	} = useCart();
	const { userData } = useUser();
	const { setAddressList, addressList, selectAddress, selectedAddress } =
		useAddress();
	const [couponCode, setCouponCode] = useState("");

	const getAddressData = () => {
		GetWithoutToken(`/shipping/user_address/${userData.userId}`)
			.then((res) => {
				setAddressList(res.address);
				selectAddress(res.address[0]);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getAddressData();
	}, []);

	useEffect(() => {
		if (discountCode) {
			setCouponCode(discountCode);
		}
	}, [discountCode]);

	const handleShowCoupon = () => {
		setCouponCodeInput(true);
	};

	const handleInputChange = (e) => {
		setCouponCode(e.target.value);
	};

	const submitCoupon = () => {
		if (couponCode === "") {
			toastHandler(ToastType.Error, "Please enter a coupon code");
			return;
		}
		const formData = new FormData();
		formData.append("coupon_code", couponCode);
		formData.append("userId", userData.userId);
		formData.append("total", totalPrice);
		PostWithoutToken(`cart/coupon`, formData)
			.then((res) => {
				if (res.status === "success") {
					toastHandler(ToastType.Success, "Coupon Applied Successfully");
					applyDiscount(
						parseFloat(res.discount_price.split(",").join("")),
						couponCode
					);
					setCouponCodeInput(false);
				}
			})
			.catch((err) => {
				toastHandler(ToastType.Error, "Something went wrong");
			});
	};

	const handleCheckout = () => {
		if (addressList.length > 0) {
			navigate("/checkout");
		} else {
			toastHandler(ToastType.Error, "Please add address to proceed");
			return;
		}
	};

	return (
		<div className="bpabh_maincontainer">
			<div className="usr_address_wapper widget_rlgap">
				<div className="component_title">Select Address</div>
				<Link to="/profile/add-address">
					<button className="primery_btn addbtn">
						Add New Address{" "}
						<svg
							width="13"
							height="13"
							viewBox="0 0 13 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect width="13" height="13" fill="white" fill-opacity="0.01" />
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M7.04167 5.95833V3.79167C7.04167 3.64801 6.9846 3.51023 6.88302 3.40865C6.78143 3.30707 6.64366 3.25 6.5 3.25C6.35634 3.25 6.21857 3.30707 6.11698 3.40865C6.0154 3.51023 5.95833 3.64801 5.95833 3.79167V5.95833H3.79167C3.64801 5.95833 3.51023 6.0154 3.40865 6.11698C3.30707 6.21857 3.25 6.35634 3.25 6.5C3.25 6.64366 3.30707 6.78143 3.40865 6.88302C3.51023 6.9846 3.64801 7.04167 3.79167 7.04167H5.95833V9.20833C5.95833 9.35199 6.0154 9.48977 6.11698 9.59135C6.21857 9.69293 6.35634 9.75 6.5 9.75C6.64366 9.75 6.78143 9.69293 6.88302 9.59135C6.9846 9.48977 7.04167 9.35199 7.04167 9.20833V7.04167H9.20833C9.35199 7.04167 9.48977 6.9846 9.59135 6.88302C9.69293 6.78143 9.75 6.64366 9.75 6.5C9.75 6.35634 9.69293 6.21857 9.59135 6.11698C9.48977 6.0154 9.35199 5.95833 9.20833 5.95833H7.04167Z"
								fill="white"
							/>
						</svg>
					</button>
				</Link>
				<div className="usr_address_sets">
					{addressList.map((address, index) => {
						return (
							<div
								key={index}
								className={`address_usr ${
									address.id === selectedAddress.id ? "selected" : ""
								}`}
								onClick={() => selectAddress(address)}
							>
								<div className="address_usr_head">
									<span className="user_name">{address.username}</span>
									<span className="address_type">
										Address Type: {address?.address_type}
									</span>
								</div>
								<div className="address_usr_full">
									{address.house}, {address.locality}
									<br></br>
									{address.landmark}, {address.city}
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className="cart_items_list widget_rlgap">
				<div className="titl_link">
					<div className="component_title">Items in your cart</div>
					<div className="link_type">
						<Link to="/menu">View Menu</Link>
					</div>
				</div>
				{products.length > 0 ? (
					products.map((productData, index) => {
						return (
							<div key={index} className="cart_item">
								<div className="item_lhsnfo">
									<div className="item_poster">
										<img src={productData.imageUrl} width="73" height="73" />
									</div>
									<div className="item_details">
										<div className="item_name">{productData.name}</div>
										<div className="item_qntprc">
											<span className="qunt_item">
												{productData.cartQuantity}
											</span>{" "}
											<span className="mthsmbl">X</span>{" "}
											<span className="prcitem">
												₹ {productData.sale_price}
											</span>
										</div>
									</div>
								</div>
								<div className="itemprc_details">
									<div className="fnlprc">
										₹{" "}
										{productData.cartQuantity *
											parseFloat(productData.sale_price)}
									</div>
									<ProductAddRemove product={productData} />
								</div>
							</div>
						);
					})
				) : (
					<div className="empty_cart_container">
						<svg
							stroke="#dcdcdc"
							fill="#dcdcdc"
							stroke-width="0"
							viewBox="0 0 576 512"
							height="100px"
							width="100px"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"></path>
						</svg>
						Cart is Empty
					</div>
				)}
			</div>
			{/* <div className="frequent_order_vcard widget_rlgap">
				<div className="component_title">Frequently Ordered Together</div>
				<div className="vcard_list">
					<div className="vcard_item">
						<div className="items_poster">
							<span className="foodtype nonveg"></span>
							<img src="../images/rectangle-item.png" alt="" />
						</div>
						<div className="item_detail">
							<div className="badge_tag">
								<span className="badge">Bestseller</span>
							</div>
							<div className="item_name">Taro Lava Bubble Tea</div>
							<div className="item_discr">
								<div className="item_srtinfo">
									Experience the unique and indulgent delight of our Taro Bubble
									Tea. Delight in the creamy creamy, earthy fl...
									<span className="link_type">Read more</span>
								</div>
							</div>
							<div className="pric_crt">
								<div className="inr_price">₹ 189</div>
								<div className="btn_styl">
									Add{" "}
									<svg
										width="13"
										height="13"
										viewBox="0 0 13 13"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect
											width="13"
											height="13"
											fill="white"
											fill-opacity="0.01"
										/>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M7.04167 5.95833V3.79167C7.04167 3.64801 6.9846 3.51023 6.88302 3.40865C6.78143 3.30707 6.64366 3.25 6.5 3.25C6.35634 3.25 6.21857 3.30707 6.11698 3.40865C6.0154 3.51023 5.95833 3.64801 5.95833 3.79167V5.95833H3.79167C3.64801 5.95833 3.51023 6.0154 3.40865 6.11698C3.30707 6.21857 3.25 6.35634 3.25 6.5C3.25 6.64366 3.30707 6.78143 3.40865 6.88302C3.51023 6.9846 3.64801 7.04167 3.79167 7.04167H5.95833V9.20833C5.95833 9.35199 6.0154 9.48977 6.11698 9.59135C6.21857 9.69293 6.35634 9.75 6.5 9.75C6.64366 9.75 6.78143 9.69293 6.88302 9.59135C6.9846 9.48977 7.04167 9.35199 7.04167 9.20833V7.04167H9.20833C9.35199 7.04167 9.48977 6.9846 9.59135 6.88302C9.69293 6.78143 9.75 6.64366 9.75 6.5C9.75 6.35634 9.69293 6.21857 9.59135 6.11698C9.48977 6.0154 9.35199 5.95833 9.20833 5.95833H7.04167Z"
											fill="#42C5EC"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div className="vcard_item">
						<div className="items_poster">
							<span className="foodtype egg"></span>
							<img src="../images/rectangle-item.png" alt="" />
						</div>
						<div className="item_detail">
							<div className="badge_tag">
								<span className="badge">Bestseller</span>
							</div>
							<div className="item_name">Taro Lava Bubble Tea</div>
							<div className="item_discr">
								<div className="item_srtinfo">
									Experience the unique and indulgent delight of our Taro Bubble
									Tea. Delight in the creamy creamy, earthy fl...
									<span className="link_type">Read more</span>
								</div>
							</div>
							<div className="pric_crt">
								<div className="inr_price">₹ 189</div>
								<div className="btn_styl">
									Add{" "}
									<svg
										width="13"
										height="13"
										viewBox="0 0 13 13"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect
											width="13"
											height="13"
											fill="white"
											fill-opacity="0.01"
										/>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M7.04167 5.95833V3.79167C7.04167 3.64801 6.9846 3.51023 6.88302 3.40865C6.78143 3.30707 6.64366 3.25 6.5 3.25C6.35634 3.25 6.21857 3.30707 6.11698 3.40865C6.0154 3.51023 5.95833 3.64801 5.95833 3.79167V5.95833H3.79167C3.64801 5.95833 3.51023 6.0154 3.40865 6.11698C3.30707 6.21857 3.25 6.35634 3.25 6.5C3.25 6.64366 3.30707 6.78143 3.40865 6.88302C3.51023 6.9846 3.64801 7.04167 3.79167 7.04167H5.95833V9.20833C5.95833 9.35199 6.0154 9.48977 6.11698 9.59135C6.21857 9.69293 6.35634 9.75 6.5 9.75C6.64366 9.75 6.78143 9.69293 6.88302 9.59135C6.9846 9.48977 7.04167 9.35199 7.04167 9.20833V7.04167H9.20833C9.35199 7.04167 9.48977 6.9846 9.59135 6.88302C9.69293 6.78143 9.75 6.64366 9.75 6.5C9.75 6.35634 9.69293 6.21857 9.59135 6.11698C9.48977 6.0154 9.35199 5.95833 9.20833 5.95833H7.04167Z"
											fill="#42C5EC"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{products.length > 0 && (
				<>
					<div className="other_order_instruc widget_rlgap">
						<div className="lght_subtitle">Any special instructions:</div>
						<textarea
							className="instruc_inpur"
							placeholder="Please mention here...."
						></textarea>
					</div>
					<div
						onClick={handleShowCoupon}
						value={couponCode}
						className="coupon_btn widget_rlgap"
					>
						<div className="lght_subtitle">Apply Coupon</div>
						<div className="primery_btn_coupon">
							<div className="btn_wrap">
								<svg
									width="26"
									height="26"
									viewBox="0 0 26 26"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M16.6635 3.85674C18.1412 3.16381 19.9562 3.42772 21.1768 4.64829C22.3974 5.86885 22.6613 7.6839 21.9683 9.16154C23.5032 9.71642 24.6 11.1865 24.6 12.9126C24.6 14.6387 23.5032 16.1088 21.9683 16.6637C22.6613 18.1413 22.3974 19.9563 21.1768 21.1769C19.9562 22.3975 18.1412 22.6614 16.6635 21.9685C16.1087 23.5033 14.6386 24.6001 12.9125 24.6001C11.1863 24.6001 9.71629 23.5033 9.16142 21.9685C7.68378 22.6614 5.86873 22.3975 4.64817 21.1769C3.4276 19.9563 3.16369 18.1413 3.85662 16.6637C2.3218 16.1088 1.22498 14.6387 1.22498 12.9126C1.22498 11.1865 2.3218 9.71642 3.85662 9.16154C3.16369 7.6839 3.4276 5.86885 4.64817 4.64829C5.86873 3.42772 7.68378 3.16381 9.16142 3.85674C9.71629 2.32192 11.1863 1.2251 12.9125 1.2251C14.6386 1.2251 16.1087 2.32192 16.6635 3.85674Z"
										fill="#673D94"
									/>
									<path
										d="M8.58863 11.0563C8.02115 10.4888 8.02115 9.56873 8.58863 9.00125C9.15611 8.43377 10.0762 8.43377 10.6437 9.00125C11.2111 9.56873 11.2111 10.4888 10.6437 11.0563C10.0762 11.6238 9.15611 11.6238 8.58863 11.0563Z"
										fill="#673D94"
									/>
									<path
										d="M14.3427 16.8104C13.7752 16.2429 13.7752 15.3228 14.3427 14.7553C14.9102 14.1878 15.8303 14.1878 16.3977 14.7553C16.9652 15.3228 16.9652 16.2429 16.3977 16.8104C15.8303 17.3778 14.9102 17.3778 14.3427 16.8104Z"
										fill="#673D94"
									/>
									<path
										d="M16.9115 8.28199L7.86937 17.3241M21.1768 4.64829C19.9562 3.42772 18.1412 3.16381 16.6635 3.85674C16.1087 2.32192 14.6386 1.2251 12.9125 1.2251C11.1863 1.2251 9.71629 2.32192 9.16142 3.85674C7.68378 3.16381 5.86873 3.42772 4.64817 4.64829C3.4276 5.86885 3.16369 7.6839 3.85662 9.16154C2.3218 9.71642 1.22498 11.1865 1.22498 12.9126C1.22498 14.6387 2.3218 16.1088 3.85662 16.6637C3.16369 18.1413 3.4276 19.9563 4.64817 21.1769C5.86873 22.3975 7.68378 22.6614 9.16142 21.9685C9.71629 23.5033 11.1863 24.6001 12.9125 24.6001C14.6386 24.6001 16.1087 23.5033 16.6635 21.9685C18.1412 22.6614 19.9562 22.3975 21.1768 21.1769C22.3974 19.9563 22.6613 18.1413 21.9683 16.6637C23.5032 16.1088 24.6 14.6387 24.6 12.9126C24.6 11.1865 23.5032 9.71642 21.9683 9.16154C22.6613 7.6839 22.3974 5.86885 21.1768 4.64829ZM10.6437 11.0563C10.0762 11.6238 9.15611 11.6238 8.58863 11.0563C8.02115 10.4888 8.02115 9.56873 8.58863 9.00125C9.15611 8.43377 10.0762 8.43377 10.6437 9.00125C11.2111 9.56873 11.2111 10.4888 10.6437 11.0563ZM16.3977 16.8104C15.8303 17.3778 14.9102 17.3778 14.3427 16.8104C13.7752 16.2429 13.7752 15.3228 14.3427 14.7553C14.9102 14.1879 15.8303 14.1879 16.3977 14.7553C16.9652 15.3228 16.9652 16.2429 16.3977 16.8104Z"
										stroke="white"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>{" "}
								Apply Coupon{" "}
							</div>
							<div className="coupon_arrow">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.28975 4.81118L14.4291 10.9505C15.0149 11.5363 15.0149 12.4861 14.4291 13.0718L8.28975 19.2112"
										stroke="#414141"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						</div>
						{couponCodeInput && (
							<div className="coupon_input_container">
								<div>
									<input
										onChange={handleInputChange}
										type="text"
										value={couponCode}
										placeholder="Enter coupon code"
									></input>
									{couponCode && (
										<svg
											onClick={() => {
												setCouponCode("");
												removeDiscount();
											}}
											className="remove_coupon"
											stroke="currentColor"
											fill="currentColor"
											stroke-width="0"
											viewBox="0 0 512 512"
											height="1em"
											width="1em"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
										</svg>
									)}
								</div>

								<button onClick={submitCoupon}>
									{discountCode && discountCode == couponCode
										? "Applied"
										: "Apply"}
								</button>
							</div>
						)}
					</div>

					<div className="billing_table">
						<div className="billing_tbl_wrapper">
							<div className="tb_head">Bill Details</div>

							<div className="tb_rows">
								<div className="tb_colum">Subtotal</div>
								<div className="tb_colum">₹ {totalPrice + discountPrice}</div>
							</div>
							<div className="tb_rows">
								<div className="tb_colum">Delivery</div>
								<div className="tb_colum">Awesome! Free Delivery</div>
							</div>
							{discountPrice != 0 && (
								<div className="tb_rows">
									<div className="tb_colum">Coupon Discount</div>
									<div className="tb_colum"> -₹ {discountPrice}</div>
								</div>
							)}
							<div className="tb_rows">
								<div className="tb_colum">GST</div>
								<div className="tb_colum">₹ 0</div>
							</div>
							<div className="tb_rows tb_billtotl">
								<div className="tb_colum">Grand Total</div>
								<div className="tb_colum">₹ {totalPrice}</div>
							</div>
						</div>
					</div>
					<div className="payment_cart" onClick={handleCheckout}>
						<div className="sticky_cart">
							<div className="cart_items">
								<span className="total_pric">₹ {totalPrice}</span>
								<span className="items_othdetil">Pay Securely</span>
							</div>
							<div className="cart_icon">
								<div className="cart_wrap">
									<div className="crtsvg">
										<img src="https://app.geezyfoods.co/tests/boba_bhai/images/upi-logo.png" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
