import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
export default function SideNavigation() {
	const navigate = useNavigate();
	const location = useLocation();
	const { userData, logoutUser } = useUser();

	const handleToggleClass = () => {
		const sideNav = document.getElementsByTagName("body")[0];
		sideNav.classList.toggle("navopen");
	};

	const closeSideNavigation = () => {
		const sideNav = document.getElementsByTagName("body")[0];
		sideNav.classList.remove("navopen");
	};

	useEffect(() => {
		if (location.pathname) {
			closeSideNavigation();
		}
	}, [location.pathname]);

	return (
		<>
			<div className="side_navigation ">
				<div className="navigation_wrap">
					<div className="closebnt" onClick={handleToggleClass}>
						<svg
							width="9"
							height="18"
							viewBox="0 0 9 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7.71016 16.1886L1.57082 10.0492C0.985036 9.46345 0.985036 8.5137 1.57082 7.92791L7.71016 1.78857"
								stroke="#414141"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>{" "}
						Back
					</div>
					<div className="user_profile">
						<div className="user_pic">
							<img src="/img/user-profile.jpg" alt="" />
						</div>
						{userData && (
							<div className="user_info">
								<div className="user_name">{userData.name}</div>
								<div className="user_contact">{userData.phone}</div>
								<Link to="/profile">
									<div className="edit_profile_btn">Edit Profile Details</div>
								</Link>
							</div>
						)}
					</div>
					<nav>
						<ul>
							<li onClick={() => navigate("/order-history")}>
								<i className="sm_icon">
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M3.60001 3.1999C3.60001 2.75807 3.95818 2.3999 4.40001 2.3999H11.6C12.0418 2.3999 12.4 2.75807 12.4 3.1999V12.7999C12.4 13.2417 12.0418 13.5999 11.6 13.5999H4.40001C3.95818 13.5999 3.60001 13.2417 3.60001 12.7999V3.1999Z"
											fill="#C2CCDE"
											fill-opacity="0.25"
										/>
										<path
											d="M5.60001 4.7999H10.4M5.60001 6.3999H8.80001M5.60001 7.9999H10.4M5.60001 9.5999H8.80001M5.60001 11.1999H10.4M4.40001 13.5999H11.6C12.0418 13.5999 12.4 13.2417 12.4 12.7999V3.1999C12.4 2.75807 12.0418 2.3999 11.6 2.3999H4.40001C3.95818 2.3999 3.60001 2.75807 3.60001 3.1999V12.7999C3.60001 13.2417 3.95818 13.5999 4.40001 13.5999Z"
											stroke="#414141"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</i>{" "}
								Order History
							</li>
							<li onClick={() => navigate("/manage-address")}>
								<i className="sm_icon">
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M8 13.1998C10.8719 13.1998 13.2 10.8717 13.2 7.9998C13.2 5.12792 10.8719 2.7998 8 2.7998C5.12812 2.7998 2.8 5.12792 2.8 7.9998C2.8 9.31172 3.28583 10.5102 4.08739 11.425L3.2 13.1998L8 13.1998Z"
											fill="#C2CCDE"
											fill-opacity="0.25"
										/>
										<path
											d="M7.9 4.7998V9.2798M7.9 10.7198V11.0398M13.2 7.9998C13.2 10.8717 10.8719 13.1998 8 13.1998L3.2 13.1998L4.08739 11.425C3.28583 10.5102 2.8 9.31172 2.8 7.9998C2.8 5.12792 5.12812 2.7998 8 2.7998C10.8719 2.7998 13.2 5.12792 13.2 7.9998Z"
											stroke="#414141"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</i>
								Manage Address
							</li>
							{/* <li>
								<i className="sm_icon">
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M4.99336 7.86138C4.91843 8.21792 4.688 8.52229 4.36516 8.69114C4.01114 8.8763 3.58887 8.8763 3.23485 8.69114C2.91201 8.52229 2.68158 8.21792 2.60665 7.86138L2.58067 7.73776C2.50355 7.3708 2.58631 6.98837 2.80824 6.68613L2.85032 6.62882C3.0723 6.32651 3.42495 6.14795 3.80001 6.14795C4.17506 6.14795 4.52771 6.32651 4.74969 6.62882L4.79177 6.68613C5.0137 6.98837 5.09646 7.3708 5.01934 7.73776L4.99336 7.86138Z"
											fill="#C2CCDE"
											fill-opacity="0.25"
										/>
										<path
											d="M1.60001 11.2267C1.60001 11.4329 1.76715 11.6001 1.97334 11.6001H4.26214C4.4093 10.9462 4.80968 10.3747 5.37502 10.0131C5.27327 9.9405 5.16131 9.88 5.04081 9.83402L4.95644 9.80182C4.21168 9.51761 3.38833 9.51761 2.64357 9.80182L2.5592 9.83402C1.98161 10.0544 1.60001 10.6085 1.60001 11.2267Z"
											fill="#C2CCDE"
											fill-opacity="0.25"
										/>
										<path
											d="M14.0267 11.6001H11.7379C11.5907 10.9463 11.1904 10.3747 10.625 10.0131C10.7268 9.94053 10.8387 9.88004 10.9592 9.83406L11.0436 9.80186C11.7883 9.51765 12.6117 9.51765 13.3564 9.80186L13.4408 9.83406C14.0184 10.0545 14.4 10.6085 14.4 11.2268C14.4 11.433 14.2329 11.6001 14.0267 11.6001Z"
											fill="#C2CCDE"
											fill-opacity="0.25"
										/>
										<path
											d="M11.0066 7.86142C11.0816 8.21797 11.312 8.52233 11.6349 8.69118C11.9889 8.87634 12.4111 8.87634 12.7652 8.69118C13.088 8.52233 13.3184 8.21797 13.3934 7.86142L13.4193 7.7378C13.4965 7.37085 13.4137 6.98841 13.1918 6.68617L13.1497 6.62886C12.9277 6.32655 12.5751 6.14799 12.2 6.14799C11.825 6.14799 11.4723 6.32655 11.2503 6.62886L11.2082 6.68617C10.9863 6.98841 10.9036 7.37085 10.9807 7.7378L11.0066 7.86142Z"
											fill="#C2CCDE"
											fill-opacity="0.25"
										/>
										<path
											d="M11.16 12.8001H4.84001C4.48654 12.8001 4.20001 12.5136 4.20001 12.1601C4.20001 11.1008 4.85527 10.1519 5.84591 9.77672L6.01222 9.71373C7.29302 9.22864 8.70699 9.22864 9.98779 9.71373L10.1541 9.77672C11.1447 10.1519 11.8 11.1008 11.8 12.1601C11.8 12.5136 11.5135 12.8001 11.16 12.8001Z"
											fill="#C2CCDE"
											fill-opacity="0.25"
										/>
										<path
											d="M7.02827 7.81568C7.6375 8.13192 8.36251 8.13192 8.97174 7.81568C9.53208 7.52482 9.93266 6.99824 10.0634 6.3806L10.1044 6.18729C10.2386 5.55333 10.0948 4.89224 9.70942 4.3713L9.64337 4.28201C9.25788 3.76097 8.64814 3.45361 8.00001 3.45361C7.35187 3.45361 6.74213 3.76097 6.35665 4.28201L6.29059 4.3713C5.90517 4.89224 5.76139 5.55333 5.89564 6.18729L5.93657 6.3806C6.06735 6.99824 6.46793 7.52482 7.02827 7.81568Z"
											fill="#C2CCDE"
											fill-opacity="0.25"
										/>
										<path
											d="M4.99336 7.86138C4.91843 8.21792 4.688 8.52229 4.36516 8.69114C4.01114 8.8763 3.58887 8.8763 3.23485 8.69114C2.91201 8.52229 2.68158 8.21792 2.60665 7.86138L2.58067 7.73776C2.50355 7.3708 2.58631 6.98837 2.80824 6.68613L2.85032 6.62882C3.0723 6.32651 3.42495 6.14795 3.80001 6.14795C4.17506 6.14795 4.52771 6.32651 4.74969 6.62882L4.79177 6.68613C5.0137 6.98837 5.09646 7.3708 5.01934 7.73776L4.99336 7.86138Z"
											stroke="#414141"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M1.60001 11.2267C1.60001 11.4329 1.76715 11.6001 1.97334 11.6001H4.26214C4.4093 10.9462 4.80968 10.3747 5.37502 10.0131C5.27327 9.9405 5.16131 9.88 5.04081 9.83402L4.95644 9.80182C4.21168 9.51761 3.38833 9.51761 2.64357 9.80182L2.5592 9.83402C1.98161 10.0544 1.60001 10.6085 1.60001 11.2267Z"
											stroke="#414141"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M14.0267 11.6001H11.7379C11.5907 10.9463 11.1904 10.3747 10.625 10.0131C10.7268 9.94053 10.8387 9.88004 10.9592 9.83406L11.0436 9.80186C11.7883 9.51765 12.6117 9.51765 13.3564 9.80186L13.4408 9.83406C14.0184 10.0545 14.4 10.6085 14.4 11.2268C14.4 11.433 14.2329 11.6001 14.0267 11.6001Z"
											stroke="#414141"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M11.0066 7.86142C11.0816 8.21797 11.312 8.52233 11.6349 8.69118C11.9889 8.87634 12.4111 8.87634 12.7652 8.69118C13.088 8.52233 13.3184 8.21797 13.3934 7.86142L13.4193 7.7378C13.4965 7.37085 13.4137 6.98841 13.1918 6.68617L13.1497 6.62886C12.9277 6.32655 12.5751 6.14799 12.2 6.14799C11.825 6.14799 11.4723 6.32655 11.2503 6.62886L11.2082 6.68617C10.9863 6.98841 10.9036 7.37085 10.9807 7.7378L11.0066 7.86142Z"
											stroke="#414141"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M11.16 12.8001H4.84001C4.48654 12.8001 4.20001 12.5136 4.20001 12.1601C4.20001 11.1008 4.85527 10.1519 5.84591 9.77672L6.01222 9.71373C7.29302 9.22864 8.70699 9.22864 9.98779 9.71373L10.1541 9.77672C11.1447 10.1519 11.8 11.1008 11.8 12.1601C11.8 12.5136 11.5135 12.8001 11.16 12.8001Z"
											stroke="#414141"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M7.02827 7.81568C7.6375 8.13192 8.36251 8.13192 8.97174 7.81568C9.53208 7.52482 9.93266 6.99824 10.0634 6.3806L10.1044 6.18729C10.2386 5.55333 10.0948 4.89224 9.70942 4.3713L9.64337 4.28201C9.25788 3.76097 8.64814 3.45361 8.00001 3.45361C7.35187 3.45361 6.74213 3.76097 6.35665 4.28201L6.29059 4.3713C5.90517 4.89224 5.76139 5.55333 5.89564 6.18729L5.93657 6.3806C6.06735 6.99824 6.46793 7.52482 7.02827 7.81568Z"
											stroke="#414141"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</i>{" "}
								Referral <span className="msginfo">Coming Soon</span>
							</li> */}
							<li onClick={() => navigate('/about-us')}>
								<i className="sm_icon">
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M7.99999 2.3999L8.627 2.43511L9.24611 2.54031L9.84956 2.71416L10.4297 2.95448L10.9794 3.25825L11.4915 3.62165L11.9598 4.0401L12.3783 4.50836L12.7416 5.02052L13.0454 5.57015L13.2857 6.15034L13.4596 6.75379L13.5648 7.3729L13.6 7.9999L13.5648 8.6269L13.4596 9.24602L13.2857 9.84947L13.0454 10.4297L12.7416 10.9793L12.3783 11.4914L11.9598 11.9597L11.4915 12.3782L10.9794 12.7416L10.4297 13.0453L9.84956 13.2856L9.24611 13.4595L8.627 13.5647L7.99999 13.5999L7.37299 13.5647L6.75388 13.4595L6.15043 13.2856L5.57025 13.0453L5.02061 12.7416L4.50845 12.3782L4.0402 11.9597L3.62174 11.4914L3.25834 10.9793L2.95457 10.4297L2.71425 9.84947L2.5404 9.24602L2.43521 8.6269L2.39999 7.9999L2.43521 7.3729L2.5404 6.75379L2.71425 6.15034L2.95457 5.57015L3.25834 5.02052L3.62174 4.50836L4.0402 4.0401L4.50845 3.62165L5.02061 3.25825L5.57025 2.95448L6.15043 2.71416L6.75388 2.54031L7.37299 2.43511L7.99999 2.3999Z"
											fill="#C2CCDE"
											fill-opacity="0.25"
										/>
										<path
											d="M7.99355 4.7999V9.2799M7.99355 10.7199V11.0399M7.99999 2.3999L8.627 2.43511L9.24611 2.54031L9.84956 2.71416L10.4297 2.95448L10.9794 3.25825L11.4915 3.62165L11.9598 4.0401L12.3783 4.50836L12.7416 5.02052L13.0454 5.57015L13.2857 6.15034L13.4596 6.75379L13.5648 7.3729L13.6 7.9999L13.5648 8.6269L13.4596 9.24602L13.2857 9.84947L13.0454 10.4297L12.7416 10.9793L12.3783 11.4914L11.9598 11.9597L11.4915 12.3782L10.9794 12.7416L10.4297 13.0453L9.84956 13.2856L9.24611 13.4595L8.627 13.5647L7.99999 13.5999L7.37299 13.5647L6.75388 13.4595L6.15043 13.2856L5.57025 13.0453L5.02061 12.7416L4.50845 12.3782L4.0402 11.9597L3.62174 11.4914L3.25834 10.9793L2.95457 10.4297L2.71425 9.84947L2.5404 9.24602L2.43521 8.6269L2.39999 7.9999L2.43521 7.3729L2.5404 6.75379L2.71425 6.15034L2.95457 5.57015L3.25834 5.02052L3.62174 4.50836L4.0402 4.0401L4.50845 3.62165L5.02061 3.25825L5.57025 2.95448L6.15043 2.71416L6.75388 2.54031L7.37299 2.43511L7.99999 2.3999Z"
											stroke="#414141"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</i>
								About Us
							</li>
							<li onClick={() => navigate("/support")}>
								<i className="sm_icon">
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M7.99999 2.3999L8.627 2.43511L9.24611 2.54031L9.84956 2.71416L10.4297 2.95448L10.9794 3.25825L11.4915 3.62165L11.9598 4.0401L12.3783 4.50836L12.7416 5.02052L13.0454 5.57015L13.2857 6.15034L13.4596 6.75379L13.5648 7.3729L13.6 7.9999L13.5648 8.6269L13.4596 9.24602L13.2857 9.84947L13.0454 10.4297L12.7416 10.9793L12.3783 11.4914L11.9598 11.9597L11.4915 12.3782L10.9794 12.7416L10.4297 13.0453L9.84956 13.2856L9.24611 13.4595L8.627 13.5647L7.99999 13.5999L7.37299 13.5647L6.75388 13.4595L6.15043 13.2856L5.57025 13.0453L5.02061 12.7416L4.50845 12.3782L4.0402 11.9597L3.62174 11.4914L3.25834 10.9793L2.95457 10.4297L2.71425 9.84947L2.5404 9.24602L2.43521 8.6269L2.39999 7.9999L2.43521 7.3729L2.5404 6.75379L2.71425 6.15034L2.95457 5.57015L3.25834 5.02052L3.62174 4.50836L4.0402 4.0401L4.50845 3.62165L5.02061 3.25825L5.57025 2.95448L6.15043 2.71416L6.75388 2.54031L7.37299 2.43511L7.99999 2.3999Z"
											fill="#C2CCDE"
											fill-opacity="0.25"
										/>
										<path
											d="M7.99999 9.2799V9.20384C7.99999 8.73712 8.24593 8.30494 8.64718 8.06655L8.995 7.8599C9.49409 7.56339 9.79999 7.02583 9.79999 6.44531C9.79999 5.53658 9.06332 4.7999 8.15458 4.7999H7.99999C7.00588 4.7999 6.19999 5.60579 6.19999 6.5999V6.70154M7.99999 10.7199V11.0399M7.99999 2.3999L8.627 2.43511L9.24611 2.54031L9.84956 2.71416L10.4297 2.95448L10.9794 3.25825L11.4915 3.62165L11.9598 4.0401L12.3783 4.50836L12.7416 5.02052L13.0454 5.57015L13.2857 6.15034L13.4596 6.75379L13.5648 7.3729L13.6 7.9999L13.5648 8.6269L13.4596 9.24602L13.2857 9.84947L13.0454 10.4297L12.7416 10.9793L12.3783 11.4914L11.9598 11.9597L11.4915 12.3782L10.9794 12.7416L10.4297 13.0453L9.84956 13.2856L9.24611 13.4595L8.627 13.5647L7.99999 13.5999L7.37299 13.5647L6.75388 13.4595L6.15043 13.2856L5.57025 13.0453L5.02061 12.7416L4.50845 12.3782L4.0402 11.9597L3.62174 11.4914L3.25834 10.9793L2.95457 10.4297L2.71425 9.84947L2.5404 9.24602L2.43521 8.6269L2.39999 7.9999L2.43521 7.3729L2.5404 6.75379L2.71425 6.15034L2.95457 5.57015L3.25834 5.02052L3.62174 4.50836L4.0402 4.0401L4.50845 3.62165L5.02061 3.25825L5.57025 2.95448L6.15043 2.71416L6.75388 2.54031L7.37299 2.43511L7.99999 2.3999Z"
											stroke="#414141"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</i>
								Help and Support
							</li>
							{userData && (
								<li
									onClick={() => {
										logoutUser();
									}}
								>
									<i className="sm_icon">
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M3.33334 8L6 5.33333M3.33334 8L6 10.6667M3.33334 8H10M6.66667 14C7.4546 14 8.23482 13.8448 8.96277 13.5433C9.69073 13.2417 10.3522 12.7998 10.9093 12.2426C11.4665 11.6855 11.9084 11.0241 12.2099 10.2961C12.5115 9.56815 12.6667 8.78793 12.6667 8C12.6667 7.21207 12.5115 6.43185 12.2099 5.7039C11.9084 4.97595 11.4665 4.31451 10.9093 3.75736C10.3522 3.20021 9.69073 2.75825 8.96277 2.45672C8.23482 2.15519 7.4546 2 6.66667 2"
												stroke="#414141"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</i>
									Logout
								</li>
							)}
						</ul>
					</nav>
					{!userData && (
						<div className="login_signup_container">
							<Link to="/login">
								<button>Log in</button>
							</Link>{" "}
							|{" "}
							<Link to="/signup">
								<button>Sign up</button>
							</Link>
						</div>
					)}
					<div className="fass_certif">FASSAI No: BDHW937CBSI29</div>
				</div>
			</div>
		</>
	);
}
