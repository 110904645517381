import React, { useState, useRef, useEffect } from "react";

const CustomSelect = ({ options, onSelect, placeHolder, valueField, nameField, defaultValue }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null);
    const optionsRef = useRef(null);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    useEffect(() => {
        if (defaultValue !== undefined) {
            setSelectedOption(defaultValue);
        }
    }, [defaultValue]);

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (value) => {
        setSelectedOption(value);
        onSelect(value);
        setIsOpen(false);
    };

    const handleOutsideClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (isOpen) {
            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
                const currentIndex = options.findIndex(option => option[valueField] === selectedOption[valueField]);
                let newIndex;
                if (e.key === "ArrowUp") {
                    newIndex = currentIndex === 0 ? options.length - 1 : currentIndex - 1;
                } else {
                    newIndex = currentIndex === options.length - 1 ? 0 : currentIndex + 1;
                }
                setHighlightedIndex(newIndex);
                setSelectedOption(options[newIndex]);
                scrollIntoView(newIndex);
            } else if (e.key === "Enter") {
                handleOptionClick(selectedOption);
            }
        }
    };

    const scrollIntoView = (index) => {
        if (optionsRef.current && optionsRef.current.children[index]) {
            optionsRef.current.children[index].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start"
            });
        }
    };

    const filteredOptions = options.filter((option) => {
        return option[nameField.replace("_h", "")].toLowerCase().startsWith(searchTerm.toLowerCase());
    });

    useEffect(() => {
        if (filteredOptions.length == 0) {
            onSelect("");
        }
    }, [searchTerm])

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    return (
        <>
            <div className="custom-select" ref={dropdownRef}>
                <div className={`selected-option ${selectedOption[nameField] ? "active" : ""}`} onClick={handleToggleDropdown}>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder={selectedOption[nameField] || placeHolder}
                    />
                    <svg
                        onClick={(e) => {
                            e.stopPropagation();
                            handleToggleDropdown();
                        }}
                        style={{ flexShrink: 0 }}
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 32 32"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z"></path>
                    </svg>
                </div>
                {isOpen && (
                    <div style={{ borderRadius: "10px" }} className="options" ref={optionsRef}>
                        {filteredOptions.map((option, index) => (
                            <div
                                key={option[valueField]}
                                className={`option ${highlightedIndex === index ? "highlighted" : ""}`}
                                onClick={() => {
                                    handleOptionClick(option);
                                    setSearchTerm("");
                                }}
                            >
                                {option[nameField]}{" "}
                                {nameField?.includes("_h") && `(${nameField.includes("_h") && option[nameField.replace("_h", "")]})`}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default CustomSelect;
