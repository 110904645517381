import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "normalize.css";
import "./index.css";
// import { makeServer } from './server';
import {
	AuthContextProvider,
	CartProvider,
	AddressProvider,
	FiltersContextProvider,
	ProductsContextProvider,
	LoaderProvider,
	UserProvider,
	SearchProvider,
} from "./frontend/contexts";

// Call make Server
// makeServer();

const root = createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<LoaderProvider>
			<AuthContextProvider>
				<ProductsContextProvider>
					<FiltersContextProvider>
						<CartProvider>
							<AddressProvider>
								<UserProvider>
									<SearchProvider>
										<App />
									</SearchProvider>
								</UserProvider>
							</AddressProvider>
						</CartProvider>
					</FiltersContextProvider>
				</ProductsContextProvider>
			</AuthContextProvider>
		</LoaderProvider>
	</React.StrictMode>
);
