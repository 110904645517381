import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useFiltersContext } from '../contexts/FiltersContextProvider';
const SharedLayout = () => {
  const { paginateIndex } = useFiltersContext();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, paginateIndex]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default SharedLayout;
