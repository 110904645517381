import { Link } from "react-router-dom";

const TodaySpotlight = ({ data }) => {
  if (data?.ad_banner?.length > 0) {
    return (
      <>
        <div className="component_bgslider component_style slider_component">
          <div className="component_title">Today's Spotlight</div>
          <div className="slider_wrapper">
            <div className="slider_container">
              {
                data.ad_banner.map((banner, index) => {
                  return (
                    <div key={index} className="slider_item">
                      <Link href="#">
                        <img src={banner.banner_image} alt="" />
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </>
    );
  }
  return null;
	
};

export default TodaySpotlight;
