import React, { createContext, useState, useEffect, useContext } from 'react';

const UserContext = createContext();

const loadUserDataFromStorage = () => {
  const storedUserData = localStorage.getItem('userData');
  return storedUserData ? JSON.parse(storedUserData) : null;
};

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(() => loadUserDataFromStorage());

  useEffect(() => {
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData));
    } else {
      localStorage.removeItem('userData');
    }
  }, [userData]);

  const updateUser = (newUserData) => {
    setUserData((prevUser) => ({
      ...prevUser,
      ...newUserData,
    }));
  };

  const logoutUser = () => {
    setUserData(null);
    localStorage.clear();
  };

  return (
    <UserContext.Provider value={{ userData, updateUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook for accessing the UserContext
export const useUser = () => {
  return useContext(UserContext);
};