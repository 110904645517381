import React, { useState } from "react";

const ReadMoreReadLess= ({ children, charLimit }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle the expanded/collapsed state
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  // Determine the displayed text based on expanded state
  const displayText = isExpanded ? children : children.slice(0, charLimit);

  return (
    <div>
      <span
        dangerouslySetInnerHTML={{
          __html: displayText
        }}
      />
      {children.length > charLimit && (
        <span
          onClick={toggleReadMore}
          className="link_type"
        >
          {isExpanded ? " Read Less" : "... Read More"}
        </span>
      )}
    </div>
  );
};

export default ReadMoreReadLess;