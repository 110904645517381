import { useEffect, useState } from "react";
import { Navbar } from "../../components";
import "./ProductListingPage.css";
import { GetWithoutToken } from "../../utils/ApiHandlers";
import { toastHandler } from "../../utils/utils";
import { ToastType } from "../../constants/constants";
import { useLocation } from "react-router-dom";
import ProductCard from "./ProductCard";
import ProductCardSkeleton from "../../components/ProductCardSkeleton";
import { useSearch } from "../../contexts/SearchContext";

export const PRODUCTTYPE = {
	VEG: 1,
	NONVEG: 2,
};

const ProductListingPage = () => {
	const location = useLocation();
	const id = location.pathname.split("-").pop();
	const [categoryData, setCategoryData] = useState(null);
	const [productFilter, setProductFilter] = useState([]);
	const [selectedFoodTypes, setSelectedFoodTypes] = useState("");
	const {
		searchResults,
		searchValue,
		updateSearchValue,
		setSearchResults,
		setStoreId,
	} = useSearch();

	const getCategoryData = () => {
		let apiUrl;
		if (id) {
			apiUrl = `category/list/${id}`;
		}
		if (location.pathname == "/menu") {
			apiUrl = `category/menulist`;
		}
		if (apiUrl) {
			GetWithoutToken(apiUrl)
				.then((res) => {
					setStoreId(res.subcategory[0].store_id);
					setCategoryData(res);
				})
				.catch((err) => {
					console.log(err);
					toastHandler(ToastType.Error, "Something went wrong");
				});
		}
	};

	useEffect(() => {
		getCategoryData();
	}, [id]);

	const handleProductFilter = (filterVal) => {
		setProductFilter([filterVal]);
	};

	const handleCheckboxChange = (type) => {
		setSelectedFoodTypes(type);
		if (type === "veg") {
			handleProductFilter(PRODUCTTYPE.VEG);
		} else if (type === "nonveg") {
			handleProductFilter(PRODUCTTYPE.NONVEG);
		}
	};

	useEffect(() => {
		setProductFilter([]);
		setSelectedFoodTypes("");
	}, [searchResults]);

	useEffect(() => {
		return () => {
			updateSearchValue("");
			setSearchResults(null);
		};
	}, []);

	if (location.pathname === "/about-us" || location.pathname === "/support") {
		return <></>;
	}

	if (!categoryData) {
		return (
			<>
				<main className="full-page">
					{Array.from({ length: 5 }).map((_, index) => {
						return <ProductCardSkeleton key={index} />;
					})}
				</main>
			</>
		);
	}

	if (searchResults) {
		return (
			<>
				<div className="bpabh_maincontainer">
					<div className="product_list component_style">
						{searchResults?.items?.length > 0 && (
							<div className="foodtype_list">
								<div
									className={`list_item ${
										selectedFoodTypes === "veg" ? "selected_food" : ""
									}`}
								>
									<label
										htmlFor="veg"
										onClick={() => handleCheckboxChange("veg")}
									>
										<span className="foodtype veg"></span> Veg
									</label>
								</div>

								<div
									className={`list_item ${
										selectedFoodTypes === "nonveg" ? "selected_food" : ""
									}`}
								>
									<label
										htmlFor="nonveg"
										onClick={() => handleCheckboxChange("nonveg")}
									>
										<span className="foodtype nonveg"></span> Non-Veg
									</label>
								</div>
							</div>
						)}
						<div className="srh_result_heading">
							{searchResults.result} Results Found 
							{/* for {searchValue} */}
						</div>
						<div className="products_items">
							{searchResults.items
								.filter((product) => {
									if (productFilter.length === 0) return product;
									return productFilter.includes(parseInt(product.product_type));
								})
								.map((product) => (
									<ProductCard key={product.id} product={product} />
								))}
						</div>
					</div>
				</div>
				<Navbar />
			</>
		);
	}

	if (categoryData) {
		return (
			<>
				<div className="bpabh_maincontainer">
					<div className="product_list component_style">
						{categoryData?.list?.length > 0 && (
							<div className="foodtype_list">
								<div
									className={`list_item ${
										selectedFoodTypes === "veg" ? "selected_food" : ""
									}`}
								>
									<label
										htmlFor="veg"
										onClick={() => handleCheckboxChange("veg")}
									>
										<span className="foodtype veg"></span> Veg
									</label>
								</div>

								<div
									className={`list_item ${
										selectedFoodTypes === "nonveg" ? "selected_food" : ""
									}`}
								>
									<label
										htmlFor="nonveg"
										onClick={() => handleCheckboxChange("nonveg")}
									>
										<span className="foodtype nonveg"></span> Non-Veg
									</label>
								</div>
							</div>
						)}
						<ul className="accordion_list">
							{categoryData.list && categoryData.list.length > 0 ? (
								categoryData.list.map((category, index) => (
									<li key={index}>
										<h3>{category.title}</h3>
										<div className="products_items">
											{category.products
												.filter((product) => {
													if (productFilter.length === 0) return product; // No filters applied, show all
													return productFilter.includes(
														parseInt(product.product_type)
													);
												})
												.map((product, productIndex) => {
													return (
														<ProductCard
															key={productIndex}
															product={product}
															category={category}
														/>
													);
												})}
										</div>
									</li>
								))
							) : (
								<div className="empty_cart_container">
									<img src="/img/No_Product.png"></img>
								</div>
							)}
						</ul>
					</div>
				</div>
				<Navbar />
			</>
		);
	} else {
		return <></>;
	}
};

export default ProductListingPage;
