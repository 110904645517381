import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetWithoutToken, PostWithoutToken } from "../../utils/ApiHandlers";
import { toastHandler } from "../../utils/utils";
import { ToastType } from "../../constants/constants";
import { useUser } from "../../contexts/UserContext";

const Profile = () => {
	const { userData } = useUser();
	const [userInfo, setUserInfo] = useState(null);
	const [errors, setErrors] = useState({ name: "", email: "" });

	const GetUserData = () => {
		GetWithoutToken(`user/profile/${userData.userId}`)
			.then((res) => {
				if (res.data.result == 1) {
					setUserInfo(res.data.user);
				}
			})
			.catch((err) => {
				toastHandler(ToastType.Error, "Something went wrong!");
			});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUserInfo({ ...userInfo, [name]: value });
		setErrors({ ...errors, [name]: "" });
	};

	const validate = () => {
		let valid = true;
		let newErrors = { name: "", email: "" };

		if (!userInfo.name || userInfo.name.trim() === "") {
			newErrors.name = "Name is required";
			valid = false;
		}

		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!userInfo.email || !emailPattern.test(userInfo.email)) {
			newErrors.email = "Invalid email address";
			valid = false;
		}

		setErrors(newErrors);
		return valid;
	};

	const handleSave = () => {
		const isValid = validate();
		if (isValid) {
			const formData = new FormData()
			formData.append("userId", userData.userId);
			formData.append("name", userInfo.name);
			formData.append("email", userInfo.email);
			PostWithoutToken(`user/update_profile`, formData)
				.then((res) => {
					if(res.data.result == 1) {
						toastHandler(ToastType.Success, "Profile data saved successfully")
					} else {
						toastHandler(ToastType.Error, "Please try again later");
					}
				})
				.catch((err) => {
					toastHandler(ToastType.Error, "Something went wrong!");
				});
		}
	};

	useEffect(() => {
		GetUserData();
	}, []);

	return (
		<>
			<div className="bpabh_maincontainer">
				<div className="blank_header widget_rlgap logolft">
					<div className="logo_blank">
						<img src="../img/sm-logo.svg" />
					</div>
				</div>
				<div className="title_bckbtn widget_rlgap">
					<div className="component_title">Edit Profile</div>
					<Link to="/" className="page_backbtn">
						<span className="backicon">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect width="24" height="24" fill="white" fill-opacity="0.01" />
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M12.207 5.79304C12.2999 5.88583 12.3737 5.99603 12.424 6.11735C12.4743 6.23866 12.5002 6.36871 12.5002 6.50004C12.5002 6.63138 12.4743 6.76142 12.424 6.88274C12.3737 7.00405 12.2999 7.11425 12.207 7.20704L8.414 11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4805 19 11.7348 19 12C19 12.2653 18.8946 12.5196 18.7071 12.7071C18.5196 12.8947 18.2652 13 18 13H8.414L12.207 16.793C12.2999 16.8858 12.3737 16.996 12.424 17.1173C12.4743 17.2387 12.5002 17.3687 12.5002 17.5C12.5002 17.6314 12.4743 17.7614 12.424 17.8827C12.3737 18.004 12.2999 18.1143 12.207 18.207C11.817 18.597 11.183 18.597 10.792 18.207L5.293 12.707C5.1119 12.5266 5.00701 12.2836 5 12.028V11.971C5.00716 11.7158 5.11205 11.4731 5.293 11.293L10.792 5.79304C10.8849 5.70007 10.9952 5.62631 11.1166 5.57598C11.238 5.52566 11.3681 5.49976 11.4995 5.49976C11.6309 5.49976 11.761 5.52566 11.8824 5.57598C12.0038 5.62631 12.1141 5.70007 12.207 5.79304Z"
									fill="#5A5A5A"
								/>
							</svg>
						</span>{" "}
						Back
					</Link>
				</div>
				<div className="user_profile edit widget_rlgap">
					<div className="profile_form">
						<div className="user_details">
							<div className="user_pic">
								<img src="../img/user-profile.jpg" alt="" />
							</div>
							<div className="user_name">{userData?.name}</div>
							<div className="user_mobile">{userData?.phone}</div>
						</div>
						<div className="form_containers userprofile_edit">
							<div className="field">
								<label>Name</label>
								<input
									name="name"
									type="text"
									value={userInfo?.name}
									onChange={handleChange}
									className={errors.name ? "input-error" : ""}
								/>
								{errors.name && <div className="error">{errors.name}</div>}
							</div>
							<div className="field">
								<label>Email ID</label>
								<input
									name="email"
									type="text"
									value={userInfo?.email}
									onChange={handleChange}
									className={errors.email ? "input-error" : ""}
								/>
								{errors.email && <div className="error">{errors.email}</div>}
							</div>
							<div className="big_btn_scondry" onClick={handleSave}>
								Save Changes
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Profile;
