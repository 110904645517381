import { Link } from "react-router-dom";
import "./Categories.css";
import React from "react";
import { useNavigate } from "react-router-dom";

const Categories = (props) => {
	const { categoryData } = props;
	const navigate = useNavigate();

	return (
		<>
			<div className="gridmenu_list component_style">
				<div className="titl_link">
					{" "}
					<div className="component_title">Our Menu</div>{" "}
					{props.hideViewMenu === true ? (
						<></>
					) : (
						<div className="link_type" onClick={() => navigate("/menu")}>
							View Menu
						</div>
					)}
				</div>
				<div className="grid_layout">
					{categoryData &&
						categoryData.category.length > 0 &&
						categoryData.category.map((item, index) => {
							return (
								<Link
									to={`/${item.sef_url}-${item.id}`}
									className="grid_item"
									key={index}
								>
									<div className="item_poster">
										<img src={item.image} alt="" />
									</div>
									<div className="item_title">{item.name}</div>
								</Link>
							);
						})}
				</div>
			</div>
		</>
		//  {/*<section classNameName='section'>
		//     <Title>Categories</Title>

		//     <div classNameName={`container ${styles.categoryContainer}`}>
		//       {categoriesFromContext.map(({ _id, categoryName, categoryImage }) => (
		//         <article
		//           key={_id}
		//           classNameName={styles.category}
		//           onClick={() => handleCategoryClick(categoryName)}
		//         >
		//           <img src={categoryImage} alt={categoryName} />
		//           <div>{categoryName}</div>
		//         </article>
		//       ))}
		//     </div>
		//   </section>*/}
	);
};

export default Categories;
