import React, { createContext, useContext, useReducer, useEffect } from "react";

const initialState = {
	products: [],
	totalItems: 0,
	totalPrice: 0,
	discountPrice: 0,
	discountCode: "",
};

const cartReducer = (state, action) => {
	switch (action.type) {
		case "ADD_TO_CART": {
			const existingProduct = state.products.find(
				(product) => product.id === action.payload.id
			);

			let updatedProducts;

			if (existingProduct) {
				updatedProducts = state.products.map((product) =>
					product.id === action.payload.id
						? { ...product, cartQuantity: product.cartQuantity + 1 }
						: product
				);
			} else {
				updatedProducts = [
					...state.products,
					{ ...action.payload, cartQuantity: 1 },
				];
			}

			// Calculate total items and total price
			const updatedTotalItems = updatedProducts.reduce(
				(total, product) => total + product.cartQuantity,
				0
			);
			const updatedTotalPrice = updatedProducts.reduce(
				(total, product) =>
					total + product.cartQuantity * parseFloat(product.price),
				0
			);

			// Calculate the total price with discount if applicable
			const finalTotalPrice =
				updatedTotalPrice - (state.discountPrice > 0 ? state.discountPrice : 0);

			return {
				...state,
				products: updatedProducts,
				totalItems: updatedTotalItems,
				totalPrice: finalTotalPrice,
			};
		}
		case "REMOVE_FROM_CART": {
			const existingProduct = state.products.find(
				(product) => product.id === action.payload.id
			);

			if (!existingProduct) return state;

			let updatedProducts;

			if (existingProduct.cartQuantity > 1) {
				updatedProducts = state.products.map((product) =>
					product.id === action.payload.id
						? { ...product, cartQuantity: product.cartQuantity - 1 }
						: product
				);
			} else {
				updatedProducts = state.products.filter(
					(product) => product.id !== action.payload.id
				);
			}

			// Calculate total items and total price
			const updatedTotalItems = updatedProducts.reduce(
				(total, product) => total + product.cartQuantity,
				0
			);
			const updatedTotalPrice = updatedProducts.reduce(
				(total, product) =>
					total + product.cartQuantity * parseFloat(product.price),
				0
			);

			// Calculate the total price with discount if applicable
			const finalTotalPrice =
				updatedTotalPrice - (state.discountPrice > 0 ? state.discountPrice : 0);

			return {
				...state,
				products: updatedProducts,
				totalItems: updatedTotalItems,
				totalPrice: finalTotalPrice,
			};
		}
		case "APPLY_DISCOUNT": {
			const { discountPrice, discountCode } = action.payload;

			// Recalculate total price with new discount
			const updatedTotalPrice = state.products.reduce(
				(total, product) =>
					total + product.cartQuantity * parseFloat(product.price),
				0
			);
			const finalTotalPrice =
				updatedTotalPrice - (discountPrice > 0 ? discountPrice : 0);

			return {
				...state,
				discountPrice: discountPrice,
				discountCode: discountCode,
				totalPrice: finalTotalPrice,
			};
		}
		case "REMOVE_DISCOUNT": {
			const updatedTotalPrice = state.products.reduce(
				(total, product) =>
					total + product.cartQuantity * parseFloat(product.price),
				0
			);

			return {
				...state,
				discountPrice: 0,
				discountCode: "",
				totalPrice: updatedTotalPrice,
			};
		}
		case "CLEAR_CART":
			return initialState;
		case "LOAD_CART_FROM_STORAGE":
			return action.payload;
		default:
			return state;
	}
};

const CartContext = createContext();

export const CartProvider = ({ children }) => {
	const initializer = () => {
		const storedCart = localStorage.getItem("cart");
		return storedCart ? JSON.parse(storedCart) : initialState;
	};

	const [state, dispatch] = useReducer(cartReducer, initialState, initializer);

	useEffect(() => {
		localStorage.setItem("cart", JSON.stringify(state));
	}, [state]);

	const addToCart = (product) => {
		dispatch({ type: "ADD_TO_CART", payload: product });
	};

	const removeFromCart = (product) => {
		dispatch({ type: "REMOVE_FROM_CART", payload: product });
	};

	const clearCart = () => {
		dispatch({ type: "CLEAR_CART" });
	};

	const applyDiscount = (discountPrice, discountCode) => {
        console.log(discountPrice, discountCode);
		dispatch({
			type: "APPLY_DISCOUNT",
			payload: { discountPrice, discountCode },
		});
	};

	const removeDiscount = () => {
		dispatch({ type: "REMOVE_DISCOUNT" });
	};

	return (
		<CartContext.Provider
			value={{
				products: state.products,
				totalItems: state.totalItems,
				totalPrice: state.totalPrice,
				discountPrice: state.discountPrice,
				discountCode: state.discountCode,
				addToCart,
				removeFromCart,
				clearCart,
				applyDiscount,
				removeDiscount
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

export const useCart = () => {
	return useContext(CartContext);
};
