import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormRow, LoginAndSignupLayout } from "../components";
import { TEST_USER, ToastType, LOGIN_CLICK_TYPE } from "../constants/constants";
import { useState, useRef, useEffect } from "react";
import { toastHandler } from "../utils/utils";
import { PostWithoutToken } from "../utils/ApiHandlers";
import CustomSelect from "../components/CustomSelect";
import { useNavigateIfRegistered } from "../hooks";
import "./style/LoginandSignup.css";
import { useUser } from "../contexts/UserContext";

export const COUNTRYCODES = [
	{ key: "+1", value: "+1" },
	{ key: "+44", value: "+44" },
	{ key: "+91", value: "+91" },
	{ key: "+33", value: "+33" },
];

const LoginPage = () => {
	const { userData } = useUser();
	const inputRef = useRef(null);
	const navigate = useNavigate();

	useNavigateIfRegistered(userData);

	const initialLoginState = {
		email: "",
		password: "",
	};
	const [userInputs, setUserInputs] = useState(initialLoginState);
	const [activeBtnLoader, setActiveBtnLoader] = useState("");
	const locationOfLogin = useLocation();

	const handleUserInput = (e) => {
		setUserInputs({ ...userInputs, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		const sideNav = document.getElementsByTagName("body")[0];
		sideNav.classList.remove("navopen");
	}, []);

	// used for both the buttons
	const handleSubmit = async (e, clickType) => {
		e.preventDefault();

		const isGuestClick = clickType === LOGIN_CLICK_TYPE.GuestClick;
		const userInfo = isGuestClick ? TEST_USER : userInputs;

		setActiveBtnLoader(clickType);

		if (isGuestClick) {
			setUserInputs(TEST_USER);
		}

		try {
			let formData = new FormData();
			formData.append("phone", userInfo.phone);
			PostWithoutToken("user/signin", formData)
				.then((res) => {
					if (res.data.result === 0) {
						toastHandler(ToastType.Error, res.data.message);
					} else {
						localStorage.setItem("userId", res.data.userId);
						toastHandler(ToastType.Success, res.data.message);
						navigate(`/otpverify`);
					}
				})
				.catch((err) => {
					console.error(err);
					toastHandler(ToastType.Error, "Something went wrong");
				});

			toastHandler(
				ToastType.Success,
				`Welcome ${userData.firstName} ${userData.lastName} 😎`
			);
			// if non-registered user comes from typing '/login' at the url, after success redirect it to '/'
			navigate(locationOfLogin?.state?.from ?? "/");
		} catch ({ response }) {
			const errorText = response?.data?.errors[0].split(".")[0];
			toastHandler(ToastType.Error, errorText);
		}

		setActiveBtnLoader("");
	};

	const handleSelect = (value) => {
		console.log(value);
	};

	if (!!userData) {
		return <main className="full-page"></main>;
	}

	return (
		<LoginAndSignupLayout>
			<div className="login__screen">
				<div className="motion_bg">
					<img src="../img/splash_screenbg.png" alt="" />
				</div>
				<div className="login_body">
					<div class="topad_banner">
						<img src="../img/matcha1.png" alt="" /> Sign Up and Get a Bubble Tea
						free with your first order !
					</div>
					<div className="logo_top">
						<img src={"../img/logo.png"} alt="" />
					</div>
					<div className="subtext_top">
						<img src={"../img/subtitle-logo.png"} alt="" />
					</div>
					<div className="signup_body">
						<div className="heading_top">Log in with your phone number.</div>
						<div className="subheading_top">
							We’ll send you an OTP for confirmation and to keep your account
							safe
						</div>
						<form
							className="signup"
							onSubmit={(e) => handleSubmit(e, LOGIN_CLICK_TYPE.RegisterClick)}
						>
							<div
								className="form_field"
								style={{ display: "flex", gap: "5px" }}
							>
								<CustomSelect
									options={COUNTRYCODES}
									onSelect={handleSelect}
									placeHolder=""
									valueField="value"
									nameField="key"
									defaultValue={{ key: "+91", value: "+91" }}
								/>
								<FormRow
									text="number"
									ref={inputRef}
									type="number"
									name="phone"
									id="phone"
									placeholder="Your mobile number"
									value={userInputs.phone}
									handleChange={handleUserInput}
									disabled={!!activeBtnLoader}
								/>
							</div>

							<div className="form_field">
								<button class="submit_but" type="submit">
									Log In
								</button>
								<div class="btngroup">
									<button class="secondry_but">
										<Link to="/signup">Sign Up</Link>
									</button>
									<button class="secondry_but">
										<Link to="/">Cancel</Link>
									</button>
								</div>
							</div>
						</form>
					</div>
					<div className="term__section">
						<label htmlFor="term">
							<input id="term" type="checkbox" name="term" defaultChecked />{" "}
							<span>
								By signing up. you agree to the{" "}
								<a href="#"> Terms of service</a> and{" "}
								<a href="#">Privacy policy .</a>
							</span>
						</label>
					</div>
				</div>
			</div>

			{/* <Title>Login</Title>

      <form onSubmit={(e) => handleSubmit(e, LOGIN_CLICK_TYPE.RegisterClick)}>
        <FormRow
          text='Email Address'
          type='email'
          name='email'
          id='email'
          placeholder='jethalal.gada@gmail.com'
          value={userInputs.email}
          handleChange={handleUserInput}
          disabled={!!activeBtnLoader}
        />
        <PasswordRow
          text='Enter Password'
          name='password'
          id='password'
          placeholder='babitaji1234'
          value={userInputs.password}
          handleChange={handleUserInput}
          disabled={!!activeBtnLoader}
        />

        <button
          disabled={!!activeBtnLoader}
          className='btn btn-block'
          type='submit'
        >
          {activeBtnLoader === LOGIN_CLICK_TYPE.RegisterClick ? (
            <span className='loader-2'></span>
          ) : (
            'Login'
          )}
        </button>

        {/* this Guest Login button is out of the form  
        <button
          disabled={!!activeBtnLoader}
          className='btn btn-block'
          onClick={(e) => handleSubmit(e, LOGIN_CLICK_TYPE.GuestClick)}
        >
          {activeBtnLoader === LOGIN_CLICK_TYPE.GuestClick ? (
            <span className='loader-2'></span>
          ) : (
            'Login as a guest'
          )}
        </button>
      </form>

      {/*
        * user journey
        * '/wishlist' (protectedRoute) -->  
        * '/login' (comes to login page, but thinks to sign up)
        * clicks Link to sign up
        * '/signup' after successful signup -->
        * '/wishlist'

        // if the non-registered user comes from wishlist and then user decides to signup, clicks the link of signup, then pass that '/wishlist' state from loginPage's state to the signup page state, so the signup page can access it and after successful signup, and user goes to wishlist..

        // (locationOfLogin?.state?.from) 
        // i.e. passing loginPage State to SignupPage State
      

      <div>
        <span>
          Don't have an account?{' '}
          <Link
            to='/signup'
            state={{ from: locationOfLogin?.state?.from ?? '/' }}
          >
            sign up
          </Link>
        </span>
      </div> */}
		</LoginAndSignupLayout>
	);
};

export default LoginPage;
