// src/components/SplashScreen.js
import React from 'react';
import './SplashScreen.css'; // Add your custom styles here
import splashScreenbg from '../../assets/splash.gif';


const SplashScreen = () => {
    return (
      <div className="splash__screen">
      {/* <div className="motion_bg"><img src={splashScreenbg} alt="" /></div> */}
         {/* <div className="splash_body"> */}
         <img src={splashScreenbg} alt=""/>
          {/* </div> */}
  </div>
    );
};

export default SplashScreen;
