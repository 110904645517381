import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LoginAndSignupLayout } from "../components";
import { ToastType } from "../constants/constants";
import { toastHandler } from "../utils/utils";
import "./style/LoginandSignup.css";
import { PostWithoutToken } from "../utils/ApiHandlers";
import { useUser } from "../contexts/UserContext";

const OtpVerify = () => {
	const { userData, updateUser } = useUser();
	const navigate = useNavigate();

	const inputRefs = useRef([]);

	const handleInputChange = (event, index) => {
		const value = event.target.value;
		if (value.length === 1 && index < inputRefs.current.length - 1) {
			inputRefs.current[index + 1].focus();
		}
	};

	const handleVerifyOtp = (e) => {
		e.preventDefault();
		const formData = new FormData();
		const otp = inputRefs.current.map((input) => input.value).join("");

		formData.append("otp", otp);
		formData.append("user_id", localStorage.getItem("userId"));
		PostWithoutToken("/user/verify_otp", formData)
			.then((res) => {
				updateUser(res.data);
				localStorage.setItem("userData", JSON.stringify(res.data));
				navigate("/");
				toastHandler(ToastType.Success, "OTP Verified Success");
			})
			.catch((error) => {
				console.error(error);
				toastHandler(ToastType.Error, "Something went wrong");
			});
	};

	const handleResendOtp = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("user_id", localStorage.getItem("userId"));
		formData.append("temp_user_id", localStorage.getItem("userId"));
		PostWithoutToken("/user/resend_otp", formData)
			.then((res) => {
				if (res.data.result == 1) {
					localStorage.setItem("userData", JSON.stringify(res.data));
					toastHandler(ToastType.Success, res.data.message);
				} else {
					toastHandler(ToastType.Error, res.data.message);
				}
			})
			.catch((error) => {
				console.error(error);
				toastHandler(ToastType.Error, "Something went wrong");
			});
	};

	if (!!userData) {
		return <main className="full-page"></main>;
	}

	return (
		<LoginAndSignupLayout>
			<div className="login__screen">
				<div className="motion_bg">
					<img src="../img/splash_screenbg.png" alt="" />
				</div>
				<div className="login_body">
					<div class="topad_banner">
						<img src="../img/matcha1.png" alt="" /> Sign Up and Get a Bubble Tea
						free with your first order !
					</div>
					<div className="logo_top">
						<img src={"../img/logo.png"} alt="" />
					</div>
					<div className="subtext_top">
						<img src={"../img/subtitle-logo.png"} alt="" />
					</div>
					<div className="signup_body">
						<div className="heading_top">Please verify the phone number</div>
						<div className="subheading_top">Enter the OTP sent to you !</div>
						<form className="signup" onSubmit={handleVerifyOtp}>
							<div class="form_field opt__fields">
								{Array.from({ length: 6 }).map((_, index) => (
									<input
										key={index}
										type="tel"
										className="optnum"
										pattern="\d*"
										maxLength={1}
										ref={(el) => (inputRefs.current[index] = el)}
										onChange={(event) => handleInputChange(event, index)}
									/>
								))}
							</div>
							<div className="form_field">
								<div className="opt__action">
									Didn't receive code?{" "}
									<span id="resendotp" onClick={handleResendOtp}>
										Resend again
									</span>
								</div>
							</div>
							<div className="form_field">
								<button className="submit_but" type="submit">
									Verify
								</button>
								<button className="cancle_but">Cancel</button>
							</div>
						</form>
					</div>
					<div className="term__section">
						<label htmlFor="term">
							<input id="term" type="checkbox" name="term" defaultChecked />{" "}
							<span>
								By signing up. you agree to the{" "}
								<a href="#"> Terms of service</a> and{" "}
								<a href="#">Privacy policy .</a>
							</span>
						</label>
					</div>
				</div>
			</div>

			{/* <Title>Login</Title>

      <form onSubmit={(e) => handleSubmit(e, LOGIN_CLICK_TYPE.RegisterClick)}>
        <FormRow
          text='Email Address'
          type='email'
          name='email'
          id='email'
          placeholder='jethalal.gada@gmail.com'
          value={userInputs.email}
          handleChange={handleUserInput}
          disabled={!!activeBtnLoader}
        />
        <PasswordRow
          text='Enter Password'
          name='password'
          id='password'
          placeholder='babitaji1234'
          value={userInputs.password}
          handleChange={handleUserInput}
          disabled={!!activeBtnLoader}
        />

        <button
          disabled={!!activeBtnLoader}
          className='btn btn-block'
          type='submit'
        >
          {activeBtnLoader === LOGIN_CLICK_TYPE.RegisterClick ? (
            <span className='loader-2'></span>
          ) : (
            'Login'
          )}
        </button>

        {/* this Guest Login button is out of the form  
        <button
          disabled={!!activeBtnLoader}
          className='btn btn-block'
          onClick={(e) => handleSubmit(e, LOGIN_CLICK_TYPE.GuestClick)}
        >
          {activeBtnLoader === LOGIN_CLICK_TYPE.GuestClick ? (
            <span className='loader-2'></span>
          ) : (
            'Login as a guest'
          )}
        </button>
      </form>

      {/*
        * user journey
        * '/wishlist' (protectedRoute) -->  
        * '/login' (comes to login page, but thinks to sign up)
        * clicks Link to sign up
        * '/signup' after successful signup -->
        * '/wishlist'

        // if the non-registered user comes from wishlist and then user decides to signup, clicks the link of signup, then pass that '/wishlist' state from loginPage's state to the signup page state, so the signup page can access it and after successful signup, and user goes to wishlist..

        // (locationOfLogin?.state?.from) 
        // i.e. passing loginPage State to SignupPage State
      

      <div>
        <span>
          Don't have an account?{' '}
          <Link
            to='/signup'
            state={{ from: locationOfLogin?.state?.from ?? '/' }}
          >
            sign up
          </Link>
        </span>
      </div> */}
		</LoginAndSignupLayout>
	);
};

export default OtpVerify;
