import { Link, Outlet, useMatch } from 'react-router-dom';
import styles from './SharedProfileLayout.module.css';

const SharedProfileLayout = () => {
  const isProfileActive = useMatch('/profile');
  const isAddressActive = useMatch('/profile/address');
  // const isOrdersActive = useMatch('/profile/order');

  const showActiveCSS = (isPageActive) => {
    return isPageActive ? styles.activeLinkCSS : styles.notActiveLinkCSS;
  };
  return (
    <section className={`half-page ${styles.pageCenter}`}>
      <main>
        <Outlet />
      </main>
    </section>
  );
};

export default SharedProfileLayout;
