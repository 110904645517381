import React from "react";
import { useCart } from "../../contexts/CartContext";

export default function ProductAddRemove({ product }) {
	const { addToCart, products, removeFromCart } = useCart();
	let tempProduct = product;
	let productInCart = products.filter((item) => item.product_id == product.product_id);
	if (productInCart.length > 0) {
		tempProduct = productInCart[0];
	}
	return (
		<>
			{(!tempProduct?.cartQuantity || tempProduct.cartQuantity == 0) ? (
				<div className="btn_styl" onClick={() => addToCart(tempProduct)}>
					Add
					<svg
						width="13"
						height="13"
						viewBox="0 0 13 13"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect width="13" height="13" fill="white" fill-opacity="0.01" />
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M7.04167 5.95833V3.79167C7.04167 3.64801 6.9846 3.51023 6.88302 3.40865C6.78143 3.30707 6.64366 3.25 6.5 3.25C6.35634 3.25 6.21857 3.30707 6.11698 3.40865C6.0154 3.51023 5.95833 3.64801 5.95833 3.79167V5.95833H3.79167C3.64801 5.95833 3.51023 6.0154 3.40865 6.11698C3.30707 6.21857 3.25 6.35634 3.25 6.5C3.25 6.64366 3.30707 6.78143 3.40865 6.88302C3.51023 6.9846 3.64801 7.04167 3.79167 7.04167H5.95833V9.20833C5.95833 9.35199 6.0154 9.48977 6.11698 9.59135C6.21857 9.69293 6.35634 9.75 6.5 9.75C6.64366 9.75 6.78143 9.69293 6.88302 9.59135C6.9846 9.48977 7.04167 9.35199 7.04167 9.20833V7.04167H9.20833C9.35199 7.04167 9.48977 6.9846 9.59135 6.88302C9.69293 6.78143 9.75 6.64366 9.75 6.5C9.75 6.35634 9.69293 6.21857 9.59135 6.11698C9.48977 6.0154 9.35199 5.95833 9.20833 5.95833H7.04167Z"
							fill="#42C5EC"
						/>
					</svg>
				</div>
			) : (
				<div className="qntbtn">
					<span onClick={() => removeFromCart(tempProduct)}>
						<svg
							width="13"
							height="13"
							viewBox="0 0 13 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect width="13" height="13" fill="white" fill-opacity="0.01" />
							<rect x="3" y="6" width="7" height="1" rx="0.5" fill="#5A5A5A" />
						</svg>
					</span>
					{tempProduct.cartQuantity}
					<span onClick={() => addToCart(tempProduct)}>
						<svg
							width="13"
							height="13"
							viewBox="0 0 13 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect width="13" height="13" fill="white" fill-opacity="0.01" />
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M7.04167 5.95833V3.79167C7.04167 3.64801 6.9846 3.51023 6.88302 3.40865C6.78143 3.30707 6.64366 3.25 6.5 3.25C6.35634 3.25 6.21857 3.30707 6.11698 3.40865C6.0154 3.51023 5.95833 3.64801 5.95833 3.79167V5.95833H3.79167C3.64801 5.95833 3.51023 6.0154 3.40865 6.11698C3.30707 6.21857 3.25 6.35634 3.25 6.5C3.25 6.64366 3.30707 6.78143 3.40865 6.88302C3.51023 6.9846 3.64801 7.04167 3.79167 7.04167H5.95833V9.20833C5.95833 9.35199 6.0154 9.48977 6.11698 9.59135C6.21857 9.69293 6.35634 9.75 6.5 9.75C6.64366 9.75 6.78143 9.69293 6.88302 9.59135C6.9846 9.48977 7.04167 9.35199 7.04167 9.20833V7.04167H9.20833C9.35199 7.04167 9.48977 6.9846 9.59135 6.88302C9.69293 6.78143 9.75 6.64366 9.75 6.5C9.75 6.35634 9.69293 6.21857 9.59135 6.11698C9.48977 6.0154 9.35199 5.95833 9.20833 5.95833H7.04167Z"
								fill="#5A5A5A"
							/>
						</svg>
					</span>
				</div>
			)}
		</>
	);
}
